// Color system
$black: #000;
$dark-10: #0A162B;
$dark-9: #3B4456;
$dark-8: #6C7380;
$dark-7: #9DA2AA;
$dark-6: #CED0D5;
$dark-5: #E9EBED;
$dark-4: #F3F3F4;
$dark-3: #F8F8F9;

$white: #fff;

$blue-dark: #125FD2;
$blue: #367BF5;
$blue-light: #E4F4FF;

$green-dark: #076349;
$green: #008F86;
$green-light: #D9F3EB;

$orange-dark: #ED6D13;
$orange: #FA8C16;
$orange-light: #FCF3D7;

$red-dark: #E12B2B;
$red: #F44336;
$red-light: #FFEFEF;

$violet-dark: #3332AA;
$violet: #5E5CE6;
$violet-light: #C5D1FB;


$primary-dark: $violet-dark;
$primary: $violet;
$primary-light: $violet-light;

$secondary-dark: $dark-8 ;
$secondary: $dark-5;
$secondary-light: $dark-3;



$success-dark: $green-dark;
$success: $green;
$success-light: $green-light;

$warning-dark: $orange-dark;
$warning: $orange;
$warning-light: $orange-light;

$danger-dark: $red-dark;
$danger: $red;
$danger-light: $red-light;


$info-dark: $violet-dark;
$info: $violet;

// Body
//
// Settings for the `<body>` element

$body-bg:                   $white;


$baseRem: .0625rem;
$v-space-base: .5rem;
$content-padding-desktop:  5rem;
$content-padding-mobile: 1.25rem;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-42: 2.625rem;
$font-size-32: 2rem;
$font-size-26: 1.625rem;
$font-size-22: 1.375rem;
$font-size-18: 1.125rem;
$font-size-16: 1rem;
$font-size-14: 0.875rem;
$font-size-12: 0.75rem;
$font-size-10: 0.625rem;

$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            500;
$font-weight-heavy:           700;
$font-weight-extra-bold:      900;

$font-weight-base:            $font-weight-light;
$line-height-base:            1.2;

//Text Style

// Headings
$h1-font-size:                $font-size-42;
$h1-font-weight:              $font-weight-light;
$h2-font-size:                $font-size-32;
$h2-font-weight:              $font-weight-bold;
$h3-font-size:                $font-size-26;
$h3-font-weight:              $font-weight-heavy;
$h4-font-size:                $font-size-22;
$h4-font-weight:              $font-weight-normal;
$h5-font-size:                $font-size-18;
$h5-font-weight:              $font-weight-heavy;
$h6-font-size:                $font-size-16;
$h6-font-weight:              $font-weight-bold;

//-------------

//$body1-color: $body-color-secondary;
//$body2-color: $body-color-secondary;
//$subtitle1-color: $body-color-secondary;
//$subtitle2-color: $body-color-secondary;
//$table-color: $body-color-secondary;
//$caption-color: $body-color-secondary;
//$overline-color: $body-color-secondary;
//$placeholder-and-passive-text-color: $gray-400;

$text-color-primary:   $dark-9;
$text-color-secondary:   $dark-8;
$text-color-helper:   $dark-7;
$text-color-disabled:   $dark-6;


$text-color-title: $dark-10;
$disabled-text-color: $dark-6;
$placeholder-and-passive-text-color: $text-color-helper;


//$body1-size: 1rem;
//$body2-size: .875rem;
//$subtitle1-size:  $body1-size;
//$subtitle2-size: $body2-size;
//$table-size: $body2-size;
//$caption-size: .75rem;
//$overline-size: .65rem;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-font-family:       null !default;
$input-btn-font-size:         .875rem;
$input-btn-line-height:       1;

$input-btn-focus-width:       .2rem !default;
//$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
//$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y:         1rem;

$input-btn-padding-y-sm:      .5rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      1rem;
$input-btn-line-height-sm:    1;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-14;
$input-btn-line-height-lg:    1;

$input-font-size: 1rem;

//$input-btn-border-width:      $border-width !default;


// Forms

$label-margin-bottom:                   0;

$input-padding-y:                       $input-btn-padding-y;

$input-border-color: $secondary;
$input-active-border-color: $primary;
$input-box-shadow: none;

$form-group-margin-bottom:              1.5rem;
$input-border-radius:                    .5rem;
$button-border-radius:                    2rem;
$cards-border-radius:                    .625rem;
//Navs

$nav-tabs-border-color:             $dark-5;
//$nav-tabs-border-width:             $border-width !default;
//$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  transparent transparent  $primary;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-bg:           $white;
$nav-tabs-link-active-border-color: $dark-5 $dark-5 $white;

// Tooltips

$tooltip-font-size:                 1rem;
$tooltip-max-width:                 300px;
$tooltip-color:                     $white;
$tooltip-bg:                        $dark-9;
$tooltip-opacity:                   .9 !default;
$tooltip-padding-y:                 .5rem;
$tooltip-padding-x:                 .5rem;
//$tooltip-margin:                    0 !default;

//$tooltip-arrow-width:               .8rem !default;
//$tooltip-arrow-height:              .4rem !default;
//$tooltip-arrow-color:               $tooltip-bg !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;

$table-color:                 $white;
$table-bg:                    null !default;
$table-accent-bg:             rgba($black, .05) !default;
$table-hover-color:           $table-color !default;
$table-hover-bg:              rgba($black, .075) !default;
$table-active-bg:             $table-hover-bg !default;

//$table-border-width:          $border-width !default;
//$table-border-color:          $border-color !default;

$table-head-bg:               $white;
$table-head-color:            $white;

//$table-dark-color:            $white !default;
//$table-dark-bg:               $gray-800 !default;
//$table-dark-accent-bg:        rgba($white, .05) !default;
//$table-dark-hover-color:      $table-dark-color !default;
//$table-dark-hover-bg:         rgba($white, .075) !default;
//$table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
//$table-dark-color:            $white !default;

$table-striped-order:         odd !default;
$table-accent-bg:             $dark-3;
$table-caption-color:         $text-color-secondary !default;

$table-bg-level:              -9 !default;
$table-border-level:          -6 !default;


// Modals

// Padding applied to the modal body

$modal-box-shadow:                   0 20px 30px rgba(10, 22, 43, 0.08);
$dialog-box-shadow:                  0 2.11781px 28.9412px rgba(10, 22, 43, 0.0725533);
$modal-border-radius:                 1rem;
//$modal-border-color:                  $dark-light;


$modal-inner-padding:               1rem !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-color:               null !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        rgba($black, .2) !default;
//$modal-content-border-width:        $border-width !default;
//$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-box-shadow-xs:       0 20px 30px rgba(10, 22, 43, 0.08);
$modal-content-box-shadow-sm-up:    0 20px 30px rgba(10, 22, 43, 0.08);;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .5 !default;
//$modal-header-border-color:         $border-color !default;
//$modal-footer-border-color:         $modal-header-border-color !default;
//$modal-header-border-width:         $modal-content-border-width !default;
//$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            1rem !default;
$modal-header-padding-x:            1rem !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl:                          1400px !default;
$modal-lg:                          1140px !default;
$modal-md:                          700px;
$modal-sm:                          400px;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;


//GRID
$grid-gutter-width: 24px;
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px
);

// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1520px
) !default;

