@import "../../base_styles/custom_variables_2_0";

.tracking-map-table-view.table {
  table-layout: fixed;

  thead {
    th {
      background: $dark-3;
      color: $text-color-title;
      padding: 1rem;

      &:first-child {
        border-radius: $input-border-radius 0 0 $input-border-radius;
      }

      &:last-child {
        border-radius: 0 $input-border-radius $input-border-radius 0;
      }
    }
  }

  .edit-statuses {
    width: 3rem;
  }
}

.statuses-search {
  width: 18rem;
}

.actions {
  width: 7rem;
}

.delete-status {
  color: $danger;
}

.width-500 {
  width: 500px;
}
