@import "../../../base_styles/custom_variables_2_0";

.wrapper {
  //display: flex;
  min-height: 100vh;
  position: relative;

  .content-wrapper {
    //width: 100%;
    //padding: 0 $content-padding-mobile;
    @media (min-width: 1200px) {
      //width: calc(100% - 18.75rem);
      //margin: 0 auto;
      //padding: 0 $content-padding-desktop;
    }
  }
}
