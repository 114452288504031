.not-found-page {
  display: grid;
  flex-direction: column;
  place-items: center;
  place-content: center;
  text-align: center;
  height: 80vh;
  .image-wrapper,
  .info {
    margin-bottom: 2rem;
  }
}
