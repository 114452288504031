@import "../../../base_styles/custom_variables_2_0";

.alert {
  box-shadow: 0 1px 4px rgba(10, 22, 43, 0.25);
  border:none;
  &.alert-danger {
    background: $danger-light;
    color: $danger;
  }

  &.alert-success {
    background: $success-light;
    color: $success;
  }

  &.alert-primary {
    background: $primary-light;
    color: $primary;
  }
  .icon-item {
    margin-left: .5rem;
    color: $dark-10;
  }
  &.undo-toast {
    .icon-item {
      display: none;
    }
  }
  .content {
    display: flex;
    align-content: center;
    align-items: center;

    .material-icons {
      margin-right: .5rem;
      align-self: center;
    }

    .btn {
      margin-left: auto;
    }
  }
}


