@import "../../base_styles/custom_variables_2_0";

.credentials-group {
  padding: 10px 20px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CED0D5;

  &.is-open {
    border: 2px dashed #5E5CE6;
    border-radius: 5px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.fixed-buttons {
  display: flex;
  justify-content: space-between;
  position: sticky;
  bottom: 20px;
}
