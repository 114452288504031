@import "../../../base_styles/custom_variables_2_0";

$preBorderColor: $dark-6;
$preHeaderColor: $dark-6;

.custom-pre {
  position: relative;
  margin-bottom: 2rem;
  color: $dark-9;
  font-weight: $font-weight-light;

  .title {
    font-size: .625rem;
    position: absolute;
    left: .5rem;
    top: -.3rem;
    color: $preHeaderColor;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding-left: .5rem;
    padding-right: .5rem;
    background: $white;
  }
  pre {
    padding: 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background: $white;
    border: 1px solid $preBorderColor;
    border-radius: $input-border-radius;
  }
}
