@import "../../../base_styles/custom_variables_2_0";
.time-picker {
  width: 7.8125rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 2rem);
  column-gap: 3rem;
  justify-content: center;
  position: relative;
    //@media (min-width: 992px) {
    //  column-gap: .25rem;
    //  width: 7.8125rem;
    //}
  &:before {
    content: '';
    display: block;
    border: 1px solid $secondary;
    border-radius: $input-border-radius;
    position: absolute;
    margin: 0 auto;
    width: 14rem;
    height: 4.5rem;
    z-index: 1;
    pointer-events: none;
    top: 3.5rem;
    left: -3rem;

    //@media (min-width: 992px) {
    //  width: 8rem;
    //  height: 39px;
    //  top:24px;
    //  left: 0;
    //}
  }

  .next, .prev {
    padding: 1rem 0;
    user-select: none;
    //@media (min-width: 992px) {
    //  padding: .25rem;
    //}
    &:hover {
      cursor: pointer;
      color: $text-color-secondary;
    }
  }

  input {
    width: 2rem;
    border: none;
    text-align: center;
    padding: 1rem 0;
    font-weight: $font-weight-light;
    &:focus {
      outline: none;
    }
    //@media (min-width: 992px) {
    //  padding: 0 .4rem;
    //}
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }


  .hours,
  .minutes,
  .seconds {
    text-align: center;
    position: relative;
  }
  .minutes {
    &:before, &:after {
      content: ':';
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before {
      left: -1.5rem;
    }
    &:after {
      right: -1.5rem;
    }
    //@media (min-width: 992px) {
    //  &:before {
    //    left: -4px;
    //  }
    //  &:after {
    //    right: -4px;
    //  }
    //}

  }

}
