@import "../../../base_styles/custom_variables_2_0";


.request-log-details {

  .heading {
    padding-bottom: .5rem;
    margin-bottom: 1rem;
  }
  .subtitle {
    font-size: $font-size-14;
    font-weight: $font-weight-normal;
    color: $text-color-secondary;
    margin-bottom: 1rem;
  }
}
