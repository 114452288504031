@import "../../base_styles/custom_variables_2_0";

.topbar {
  border: 1px solid $dark-6;
  border-radius: 0.625rem;
  padding: 1.5rem 1.5rem 0 1.5rem;
  &.no-filter {
    padding: 1.5rem;
    .top-bar-data {
      margin-bottom: 0;
    }
  }
 .top-bar-data {
   align-content: center;
   align-items: center;
   margin-bottom: 1.5rem;
   @media (min-width: 1200px){
     display: flex;
   }
   &.opened {
     .nav  {
       max-height: 230px;
       opacity: 1;
       height: auto;
       transition: all .3s ease-in;
     }
   }
   .nav{
     flex-direction: column;
     flex-wrap: nowrap;
     max-height: 0;
     overflow: hidden;
     opacity: 0;
     transition: opacity .3s ease-in;
     @media (min-width: 1200px) {
       flex-direction: row;
       column-gap: .5rem;
       opacity: 1;
       max-height: none;
     }
     .nav-item {
       position: relative;
       text-align: left;
       margin-bottom: .5rem;
       @media (min-width: 1200px){
         margin-bottom: 0;
         .collapse, .collapsing {
           display: none;
           opacity: 0;
           visibility: hidden;
           position: absolute;
           bottom: 0;
           left: -200%;
           transition: opacity .2s ease;
         }
         &:hover {
           .nav-link:not(.active) {
             background: $dark-6;
             &[aria-expanded = true] {
               background: $dark-6;
             }
           }
           .collapse, .collapsing {
             //display: block;
             opacity: 1;
             visibility: visible;
             bottom: 0;
             left: 100%;
             transition: opacity .5s ease;
           }
         }
       }
     }

     .nav-link {
       display: inline-flex;
       padding: .5rem 1rem ;
       font-size: $font-size-22;
       line-height: 1;
       align-items: center;
       color: $dark-10;
       font-weight: $font-weight-normal;
       text-transform: capitalize;
       border-radius: 40px;
       transition: all .3s ease-in-out;
       .icon-item {
         margin-right: .5rem;
         margin-bottom: 0;
         @media (min-width: 1200px){
           margin-right: 0;
           margin-bottom: .5rem;
         }

         &.boxed {
           background: $dark-9;
           border: none;
           display: flex;
           padding: 0.3rem;
         }
       }
       .expand-icon {
         position: absolute;
         right: 0;
         @media (min-width: 1200px){
           top: 1rem;
           margin: 0;
         }
       }
       &.parent {
         position: relative;
         & + .collapse, & + .collapsing {
           .nav-link {  // submenu items
             padding-left: 3.4rem;
             background: transparent;
             text-transform: none;
             font-size: 1rem;
             font-weight: $font-weight-normal;
           }
           @media (min-width: 1200px){
             background: $dark-6;
             width: 15rem;
             .nav-link {
               display: block;
               padding: 1rem;
               &:hover {
                 color: $dark-7;
               }
             }
           }
         }
       }


       &[aria-expanded = true] {
         background: $dark-6;
         position: relative;
         .icon-item {
           &.boxed {
             .material-icons {
               color: $white;
             }
           }
         }

         & + .collapse, .collapsing {
           .nav {
             .nav-link {
               &.active {
                 color: $primary;
                 @media (min-width: 1200px){
                   color: $dark-10;
                   &:hover {
                     color: $dark-7;
                   }
                 }
               }
             }
           }
         }
       }
       @media (min-width: 1200px){
         &[aria-expanded = true] {
           background: $dark-10;
           &:hover {
             background: $dark-6;
           }
         }
       }
       &.collapsed {
         .expand-icon {
           transform: translateY(-50%);
         }
       }

       &.active {
         background: $dark-10;
         color: $white;
         .icon-item {
           &.boxed {
             background: $white;
             .material-icons {
               color: $primary;
             }
           }
         }

       }
       @media (min-width: 1200px){
         padding: .25rem 1rem;
         position: relative;
         display: flex;
         flex-direction: column;
         justify-content: center;
         transition: all .3s ease-in-out;
       }
     }
   }
   .logo-wrapper {
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-bottom: 1rem;
     .logo {
       width: auto;
     }
     @media (min-width: 1200px) {
       margin-right: 1rem;
       margin-bottom: 0;
     }
   }

   .info-wrapper {
     display: flex;
     align-items: center;
     align-content: center;
     justify-content: space-between;
     @media (min-width: 1200px) {
       margin-left: auto;
       flex-direction: column-reverse;
     }
   }
   .date-info {
     display: flex;
     flex-direction: column;
     margin-right: 1rem;
     .day-details {
       margin-bottom: .25rem;
     }
     @media (min-width: 576px) {
       flex-direction: row;
       .day-details {
         margin-right: .5rem;
       }
     }
     @media (min-width: 1200px){
       margin-left: auto;
     }
   }

   .user-info {
     color: $dark-9;
     margin: 0 0 .5rem;
     @media (min-width: 1200px){
       margin: 0;
     }
   }
 }

}
