.health-check-details {
  table {
    tr {
      th {
        &:nth-child(1) {
          width: 6rem;
        }
        &:nth-child(2) {
          width: 12rem;
        }
        &:nth-child(3) {
          width: 7rem;
        }
      }
    }
  }
}
