.dialog-bgr {
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .5;
}
.dialog {
  .dialog-header {
    h4 {
      margin-bottom: 1rem;
      display: flex;
      align-content: center;
      align-items: center;
      .icon-item {
        margin-right: .5rem;
      }
    }
  }
  .dialog-text {
    margin-bottom: 2rem;
  }
  .button-wrapper {
    .btn:last-child {
      margin-left: .5rem;
    }
  }
}
