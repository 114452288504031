.icon-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.boxed {
    border-radius: 4px;
    background: transparent;
    border: 1px solid;
    min-width: 1.25rem;
    min-height: 1.25rem;

  }
}