@import "../../../base_styles/custom_variables_2_0";

.small-dialog {
  .modal-dialog {
    .modal-content {
      padding: 1.5rem 2rem;
      min-height: 0;
    }
    .close-button {
      position: absolute;
      right: 1rem;
      top: 1rem;
      display: inline-block;
      cursor: pointer;
    }
  }
}

.modal-dialog {
  &.modal-sm {
    max-width: $modal-sm;
    .modal-content {
      box-shadow: $dialog-box-shadow
    }
  }
  button {
    .spinner {
      min-height: auto;
      top:50%;
    }
  }

  .modal-content {
    overflow: hidden;
    //padding-top: 5.125rem;
    box-shadow: $modal-box-shadow;
    border-color: transparent;
    min-height: 70vh;
    border-radius: $modal-border-radius;
    >.spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .close-button {
    position: absolute;
    right: 2rem;
    top: 2rem;
    display: inline-block;
    cursor: pointer;
  }
}

.modal-backdrop {
  background: $dark-10;
  &.show {
    opacity: .5;
  }
}
