@import "../../../base_styles/custom_variables_2_0";

//.with-tooltip {
//  .custom-tooltip {
//    // display: none;
//    visibility: hidden;
//    opacity: 0;
//    transition: opacity .3s ease;
//  }
//  &:hover{
//    .custom-tooltip {
//      //display: block;
//      visibility: visible;
//      opacity: 1;
//      transition: opacity .3s ease;
//    }
//  }
//}

.custom-tooltip {
  position: absolute;
  padding: $tooltip-padding-y $tooltip-padding-x;
  background: $tooltip-bg;
  color: $tooltip-color;
  max-width: $tooltip-max-width;
  border-radius: .25rem;
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
  z-index: 1100;
  span{
    display: block;
  }
  &.hide-bgr {
    * {
      background: transparent !important;
      border:none !important;
      margin: 0 !important;
      word-break: break-all;
      color: $tooltip-color !important;
    }
  }
}
