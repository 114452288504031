@import "../../base_styles/custom_variables_2_0";

.login-button {
  margin: 0 auto;
  width: 75%;
}
.error-info {
  text-align: center;
  height: 2rem;
}
