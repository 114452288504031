@import "../../base_styles/custom_variables_2_0";

.credentials-view {
  .credentials-table-view.table {
    table-layout: fixed;

    thead {
      th {
        background: $dark-3;
        color: $text-color-title;
        padding: 1rem;

        &:first-child {
          border-radius: $input-border-radius 0 0 $input-border-radius;
          width: 3rem;
        }

        &:last-child {
          border-radius: 0 $input-border-radius $input-border-radius 0;
          width: 5rem;
        }

        &.header-carrier {
          width: 8rem;
        }

        &.header-id {
          width: 6rem;
        }

        &.header-name {
          width: 14rem;
        }

      }
    }

    .services-list {
      display: none;
      background: $dark-3;

      &.is-open {
        visibility: visible;
        display: table-row;
      }
    }
  }
}
