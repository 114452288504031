@import "../../../base_styles/custom_variables_2_0";


.custom-select-advanced-wrap {
  position: relative;
  cursor: pointer;

  .custom-select-advanced {
    padding-right: 2rem;
    display: flex;
    align-items: center;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & ~ .icon-item {
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: .5rem;
      color: $dark-10;
    }

    & ~ .custom-icon {
      position: absolute;
      top: 50%;
      left: .5rem;
      transform: translateY(-50%);
    }
  }

}

.custom-select-advanced-wrap {
  & + .custom-progress {
    margin-top: .25rem;
  }
}

.custom-select-advanced-box {
  &.icon-left {
    .custom-select-advanced {
      padding-left: 2rem;
    }
  }
  .dropdown-list {
    &.drop-up {
      bottom: 3rem;
    }

    &.drop-up-small {
      bottom: 2rem;
    }

    &-item {
      padding: .5rem 1rem;
      min-height: 35px;

      &:hover,
      &.active {
        background: $secondary-light;
      }

      &.selected {
        background: $primary-light;
      }

    }

    .custom-input-box {
      margin: 0 1rem;
    }

  }

  &.disabled {
    label {
      color: $placeholder-and-passive-text-color;
    }

    .custom-select-advanced-wrap {
      .icon-item {
        color: $placeholder-and-passive-text-color;
      }
    }
  }

  .sub-message {
    position: absolute;
    top: 105%;
    left: 1rem
  }
}
