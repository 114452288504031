@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../../static/media/material-icons.cd782f38.woff2) format("woff2"), url(../../static/media/material-icons.8208136f.woff) format("woff"); }

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

@font-face {
  font-family: "Material Icons Outlined";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../../static/media/material-icons-outlined.028c7b0f.woff2) format("woff2"), url(../../static/media/material-icons-outlined.17fbb60f.woff) format("woff"); }

.material-icons-outlined {
  font-family: "Material Icons Outlined";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../../static/media/material-icons-round.9d80a420.woff2) format("woff2"), url(../../static/media/material-icons-round.f3a3e8f3.woff) format("woff"); }

.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

@font-face {
  font-family: "Material Icons Sharp";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../../static/media/material-icons-sharp.32411a7d.woff2) format("woff2"), url(../../static/media/material-icons-sharp.2531b83d.woff) format("woff"); }

.material-icons-sharp {
  font-family: "Material Icons Sharp";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

@font-face {
  font-family: "Material Icons Two Tone";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(../../static/media/material-icons-two-tone.2825bc0e.woff2) format("woff2"), url(../../static/media/material-icons-two-tone.f5925d0a.woff) format("woff"); }

.material-icons-two-tone {
  font-family: "Material Icons Two Tone";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: 'liga';
          font-feature-settings: 'liga'; }

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #367BF5;
  --indigo: #6610f2;
  --purple: #7C4DFF;
  --pink: #e83e8c;
  --red: #F44336;
  --orange: #FA8C16;
  --yellow: #FFB300;
  --green: #008F86;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c7380;
  --gray-dark: #3b4456;
  --primary: #5E5CE6;
  --secondary: #E9EBED;
  --success: #008F86;
  --info: #5E5CE6;
  --warning: #FA8C16;
  --danger: #F44336;
  --light: #e6e8ea;
  --dark: #0A162B;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1600px;
  --font-family-sans-serif: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.2;
  color: #0A162B;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 500; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #5E5CE6;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #2321d5;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c7380;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.625rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.625rem; }

h4, .h4 {
  font-size: 1.375rem; }

h5, .h5 {
  font-size: 1.125rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c7380; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ced0d5;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c7380; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #0A162B;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 500; }

pre {
  display: block;
  font-size: 87.5%;
  color: #0A162B; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1600px) {
    .container {
      max-width: 1520px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1520px; } }

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px; }

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-order: -1;
          order: -1; }

.order-last {
  -webkit-order: 13;
          order: 13; }

.order-0 {
  -webkit-order: 0;
          order: 0; }

.order-1 {
  -webkit-order: 1;
          order: 1; }

.order-2 {
  -webkit-order: 2;
          order: 2; }

.order-3 {
  -webkit-order: 3;
          order: 3; }

.order-4 {
  -webkit-order: 4;
          order: 4; }

.order-5 {
  -webkit-order: 5;
          order: 5; }

.order-6 {
  -webkit-order: 6;
          order: 6; }

.order-7 {
  -webkit-order: 7;
          order: 7; }

.order-8 {
  -webkit-order: 8;
          order: 8; }

.order-9 {
  -webkit-order: 9;
          order: 9; }

.order-10 {
  -webkit-order: 10;
          order: 10; }

.order-11 {
  -webkit-order: 11;
          order: 11; }

.order-12 {
  -webkit-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1600px) {
  .col-xxl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    -webkit-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    -webkit-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    -webkit-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    -webkit-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    -webkit-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    -webkit-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    -webkit-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    -webkit-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    -webkit-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    -webkit-order: -1;
            order: -1; }
  .order-xxl-last {
    -webkit-order: 13;
            order: 13; }
  .order-xxl-0 {
    -webkit-order: 0;
            order: 0; }
  .order-xxl-1 {
    -webkit-order: 1;
            order: 1; }
  .order-xxl-2 {
    -webkit-order: 2;
            order: 2; }
  .order-xxl-3 {
    -webkit-order: 3;
            order: 3; }
  .order-xxl-4 {
    -webkit-order: 4;
            order: 4; }
  .order-xxl-5 {
    -webkit-order: 5;
            order: 5; }
  .order-xxl-6 {
    -webkit-order: 6;
            order: 6; }
  .order-xxl-7 {
    -webkit-order: 7;
            order: 7; }
  .order-xxl-8 {
    -webkit-order: 8;
            order: 8; }
  .order-xxl-9 {
    -webkit-order: 9;
            order: 9; }
  .order-xxl-10 {
    -webkit-order: 10;
            order: 10; }
  .order-xxl-11 {
    -webkit-order: 11;
            order: 11; }
  .order-xxl-12 {
    -webkit-order: 12;
            order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #fff; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #ced0d5; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ced0d5; }
  .table tbody + tbody {
    border-top: 2px solid #ced0d5; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #ced0d5; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ced0d5; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F8F8F9; }

.table-hover tbody tr:hover {
  color: #3b4456;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d2d1f8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #abaaf2; }

.table-hover .table-primary:hover {
  background-color: #bcbbf5; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bcbbf5; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #f9f9fa; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f4f5f6; }

.table-hover .table-secondary:hover {
  background-color: #ebebee; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #ebebee; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8e0dd; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ac5c0; }

.table-hover .table-success:hover {
  background-color: #a6d8d5; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a6d8d5; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d2d1f8; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #abaaf2; }

.table-hover .table-info:hover {
  background-color: #bcbbf5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #bcbbf5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fedfbe; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fcc386; }

.table-hover .table-warning:hover {
  background-color: #fed3a5; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fed3a5; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f99d96; }

.table-hover .table-danger:hover {
  background-color: #fbb3af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbb3af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f8f9f9; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f2f3f4; }

.table-hover .table-light:hover {
  background-color: #eaeded; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eaeded; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #babec4; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #808691; }

.table-hover .table-dark:hover {
  background-color: #acb1b8; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #acb1b8; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #3b4456;
  border-color: #4b566d; }

.table .thead-light th {
  color: #fff;
  background-color: #fff;
  border-color: #ced0d5; }

.table-dark {
  color: #fff;
  background-color: #3b4456; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #4b566d; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1em + 2rem + 2px);
  padding: 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #E9EBED;
  border-radius: 0.5rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #cbcbf7;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c7380;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c7380;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c7380;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e6e8ea;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(1rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.875rem;
  line-height: 1; }

.col-form-label-sm {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1;
  color: #0A162B;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1em + 1rem + 2px);
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1.5rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c7380; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #008F86; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: .1rem;
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
  background-color: rgba(0, 143, 134, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #008F86;
  padding-right: calc(1em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23008F86' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.5rem) center;
  background-size: calc(0.5em + 1rem) calc(0.5em + 1rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #008F86;
    box-shadow: 0 0 0 0.2rem rgba(0, 143, 134, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1em + 2rem);
  background-position: top calc(0.25em + 0.5rem) right calc(0.25em + 0.5rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #008F86;
  padding-right: calc(0.75em + 3.25rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b4456' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23008F86' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.5em + 1rem) calc(0.5em + 1rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #008F86;
    box-shadow: 0 0 0 0.2rem rgba(0, 143, 134, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #008F86; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #008F86; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #008F86; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00c2b6;
  background-color: #00c2b6; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 143, 134, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #008F86; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #008F86; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #008F86;
  box-shadow: 0 0 0 0.2rem rgba(0, 143, 134, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F44336; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 0.5rem;
  margin-top: .1rem;
  font-size: 1rem;
  line-height: 1.2;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #F44336;
  padding-right: calc(1em + 2rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44336' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F44336' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.25em + 0.5rem) center;
  background-size: calc(0.5em + 1rem) calc(0.5em + 1rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #F44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1em + 2rem);
  background-position: top calc(0.25em + 0.5rem) right calc(0.25em + 0.5rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #F44336;
  padding-right: calc(0.75em + 3.25rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b4456' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44336' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F44336' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.5em + 1rem) calc(0.5em + 1rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #F44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F44336; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #F44336; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #F44336; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f77066;
  background-color: #f77066; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #F44336; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F44336; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #F44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.form-inline {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: -webkit-flex;
      display: flex;
      -webkit-flex: 0 0 auto;
              flex: 0 0 auto;
      -webkit-flex-flow: row wrap;
              flex-flow: row wrap;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      -webkit-flex-shrink: 0;
              flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #0A162B;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 1rem 2.375rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #0A162B;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #5E5CE6;
  border-color: #5E5CE6; }
  .btn-primary:hover {
    color: #fff;
    background-color: #3d3be1;
    border-color: #3230df; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #3d3be1;
    border-color: #3230df;
    box-shadow: 0 0 0 0.2rem rgba(118, 116, 234, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #5E5CE6;
    border-color: #5E5CE6; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #3230df;
    border-color: #2725de; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(118, 116, 234, 0.5); }

.btn-secondary {
  color: #0A162B;
  background-color: #E9EBED;
  border-color: #E9EBED; }
  .btn-secondary:hover {
    color: #0A162B;
    background-color: #d4d8dc;
    border-color: #cdd2d6; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #0A162B;
    background-color: #d4d8dc;
    border-color: #cdd2d6;
    box-shadow: 0 0 0 0.2rem rgba(200, 203, 208, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #0A162B;
    background-color: #E9EBED;
    border-color: #E9EBED; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #0A162B;
    background-color: #cdd2d6;
    border-color: #c6cbd0; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(200, 203, 208, 0.5); }

.btn-success {
  color: #fff;
  background-color: #008F86;
  border-color: #008F86; }
  .btn-success:hover {
    color: #fff;
    background-color: #006962;
    border-color: #005c56; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #006962;
    border-color: #005c56;
    box-shadow: 0 0 0 0.2rem rgba(38, 160, 152, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #008F86;
    border-color: #008F86; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #005c56;
    border-color: #004f4a; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 160, 152, 0.5); }

.btn-info {
  color: #fff;
  background-color: #5E5CE6;
  border-color: #5E5CE6; }
  .btn-info:hover {
    color: #fff;
    background-color: #3d3be1;
    border-color: #3230df; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #3d3be1;
    border-color: #3230df;
    box-shadow: 0 0 0 0.2rem rgba(118, 116, 234, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #5E5CE6;
    border-color: #5E5CE6; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #3230df;
    border-color: #2725de; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(118, 116, 234, 0.5); }

.btn-warning {
  color: #0A162B;
  background-color: #FA8C16;
  border-color: #FA8C16; }
  .btn-warning:hover {
    color: #fff;
    background-color: #e57905;
    border-color: #d87205; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #e57905;
    border-color: #d87205;
    box-shadow: 0 0 0 0.2rem rgba(214, 122, 25, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #0A162B;
    background-color: #FA8C16;
    border-color: #FA8C16; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #d87205;
    border-color: #cc6c04; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 122, 25, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #F44336;
  border-color: #F44336; }
  .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d;
    box-shadow: 0 0 0 0.2rem rgba(246, 95, 84, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 95, 84, 0.5); }

.btn-light {
  color: #0A162B;
  background-color: #e6e8ea;
  border-color: #e6e8ea; }
  .btn-light:hover {
    color: #0A162B;
    background-color: #d1d5d9;
    border-color: #cacfd3; }
  .btn-light:focus, .btn-light.focus {
    color: #0A162B;
    background-color: #d1d5d9;
    border-color: #cacfd3;
    box-shadow: 0 0 0 0.2rem rgba(197, 201, 205, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #0A162B;
    background-color: #e6e8ea;
    border-color: #e6e8ea; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #0A162B;
    background-color: #cacfd3;
    border-color: #c3c8cd; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(197, 201, 205, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #0A162B;
  border-color: #0A162B; }
  .btn-dark:hover {
    color: #fff;
    background-color: #03060c;
    border-color: #000102; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #03060c;
    border-color: #000102;
    box-shadow: 0 0 0 0.2rem rgba(47, 57, 75, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #0A162B;
    border-color: #0A162B; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #000102;
    border-color: black; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(47, 57, 75, 0.5); }

.btn-outline-primary {
  color: #5E5CE6;
  border-color: #5E5CE6; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #5E5CE6;
    border-color: #5E5CE6; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #5E5CE6;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #5E5CE6;
    border-color: #5E5CE6; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.5); }

.btn-outline-secondary {
  color: #E9EBED;
  border-color: #E9EBED; }
  .btn-outline-secondary:hover {
    color: #0A162B;
    background-color: #E9EBED;
    border-color: #E9EBED; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(233, 235, 237, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #E9EBED;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #0A162B;
    background-color: #E9EBED;
    border-color: #E9EBED; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(233, 235, 237, 0.5); }

.btn-outline-success {
  color: #008F86;
  border-color: #008F86; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #008F86;
    border-color: #008F86; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 143, 134, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #008F86;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #008F86;
    border-color: #008F86; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 143, 134, 0.5); }

.btn-outline-info {
  color: #5E5CE6;
  border-color: #5E5CE6; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #5E5CE6;
    border-color: #5E5CE6; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #5E5CE6;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #5E5CE6;
    border-color: #5E5CE6; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.5); }

.btn-outline-warning {
  color: #FA8C16;
  border-color: #FA8C16; }
  .btn-outline-warning:hover {
    color: #0A162B;
    background-color: #FA8C16;
    border-color: #FA8C16; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 140, 22, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FA8C16;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #0A162B;
    background-color: #FA8C16;
    border-color: #FA8C16; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 140, 22, 0.5); }

.btn-outline-danger {
  color: #F44336;
  border-color: #F44336; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #F44336;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.btn-outline-light {
  color: #e6e8ea;
  border-color: #e6e8ea; }
  .btn-outline-light:hover {
    color: #0A162B;
    background-color: #e6e8ea;
    border-color: #e6e8ea; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(230, 232, 234, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #e6e8ea;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #0A162B;
    background-color: #e6e8ea;
    border-color: #e6e8ea; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(230, 232, 234, 0.5); }

.btn-outline-dark {
  color: #0A162B;
  border-color: #0A162B; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #0A162B;
    border-color: #0A162B; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(10, 22, 43, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #0A162B;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #0A162B;
    border-color: #0A162B; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(10, 22, 43, 0.5); }

.btn-link {
  font-weight: 400;
  color: #5E5CE6;
  text-decoration: none; }
  .btn-link:hover {
    color: #2321d5;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c7380;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1.5rem 2.375rem;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.825rem 1.5rem;
  font-size: 0.6rem;
  line-height: 1;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #0A162B;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1600px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e6e8ea; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #0A162B;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #050b16;
    text-decoration: none;
    background-color: #F1FAFE; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #5E5CE6; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c7380;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c7380;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #0A162B; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-flex;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.78125rem;
  padding-left: 1.78125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.78125rem;
  padding-left: 1.78125rem; }

.btn-group-vertical {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    -webkit-flex: 1 1;
            flex: 1 1;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: -webkit-flex;
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 1rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e6e8ea;
  border: 1px solid #E9EBED;
  border-radius: 0.5rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1em + 1rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.2rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.1rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #5E5CE6;
    background-color: #5E5CE6; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #cbcbf7; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #f7f7fe;
    border-color: #f7f7fe; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c7380; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e6e8ea; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.1rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.1rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #5E5CE6;
  background-color: #5E5CE6; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(94, 92, 230, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(94, 92, 230, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(94, 92, 230, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.1rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(94, 92, 230, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1em + 2rem + 2px);
  padding: 1rem 1.75rem 1rem 0.75rem;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b4456' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #E9EBED;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #cbcbf7;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c7380;
    background-color: #e6e8ea; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: 1rem; }

.custom-select-lg {
  height: calc(1em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 0.875rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1em + 2rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1em + 2rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #cbcbf7;
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e6e8ea; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1em + 2rem + 2px);
  padding: 1rem 0.75rem;
  font-weight: 300;
  line-height: 1;
  color: #495057;
  background-color: #fff;
  border: 1px solid #E9EBED;
  border-radius: 0.5rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1em + 2rem);
    padding: 1rem 0.75rem;
    line-height: 1;
    color: #495057;
    content: "Browse";
    background-color: #e6e8ea;
    border-left: inherit;
    border-radius: 0 0.5rem 0.5rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(94, 92, 230, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(94, 92, 230, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(94, 92, 230, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #5E5CE6;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #f7f7fe; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #ced0d5;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #5E5CE6;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #f7f7fe; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #ced0d5;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #5E5CE6;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #f7f7fe; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #ced0d5;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #ced0d5;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c7380;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #E9EBED; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: transparent transparent #5E5CE6; }
    .nav-tabs .nav-link.disabled {
      color: #6c7380;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #5E5CE6;
    background-color: #fff;
    border-color: #E9EBED #E9EBED #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5E5CE6; }

.nav-fill .nav-item {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  -webkit-flex-basis: 100%;
          flex-basis: 100%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-items: center;
          align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1599.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      -webkit-flex-direction: row;
              flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: -webkit-flex !important;
      display: flex !important;
      -webkit-flex-basis: auto;
              flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-basis: auto;
            flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 12px; }

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px; }
    .card-deck .card {
      -webkit-flex: 1 0;
              flex: 1 0;
      margin-right: 12px;
      margin-bottom: 0;
      margin-left: 12px; } }

.card-group > .card {
  margin-bottom: 12px; }

@media (min-width: 576px) {
  .card-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap; }
    .card-group > .card {
      -webkit-flex: 1 0;
              flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e6e8ea;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c7380;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c7380; }

.pagination {
  display: -webkit-flex;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 1rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0A162B;
  background-color: #fff;
  border: 1px solid #ced0d5; }
  .page-link:hover {
    z-index: 2;
    color: #2321d5;
    text-decoration: none;
    background-color: #e6e8ea;
    border-color: #ced0d5; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #7C4DFF;
  border-color: #7C4DFF; }

.page-item.disabled .page-link {
  color: #6c7380;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #ced0d5; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.75em 1rem;
  font-size: 75%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #5E5CE6; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #3230df; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.5); }

.badge-secondary {
  color: #0A162B;
  background-color: #E9EBED; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #0A162B;
    background-color: #cdd2d6; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(233, 235, 237, 0.5); }

.badge-success {
  color: #fff;
  background-color: #008F86; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #005c56; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 143, 134, 0.5); }

.badge-info {
  color: #fff;
  background-color: #5E5CE6; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #3230df; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(94, 92, 230, 0.5); }

.badge-warning {
  color: #0A162B;
  background-color: #FA8C16; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #0A162B;
    background-color: #d87205; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(250, 140, 22, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #F44336; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ea1c0d; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.badge-light {
  color: #0A162B;
  background-color: #e6e8ea; }
  a.badge-light:hover, a.badge-light:focus {
    color: #0A162B;
    background-color: #cacfd3; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(230, 232, 234, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #0A162B; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #000102; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(10, 22, 43, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e6e8ea;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 500; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #313078;
  background-color: #dfdefa;
  border-color: #d2d1f8; }
  .alert-primary hr {
    border-top-color: #bcbbf5; }
  .alert-primary .alert-link {
    color: #222154; }

.alert-secondary {
  color: #797a7b;
  background-color: #fbfbfb;
  border-color: #f9f9fa; }
  .alert-secondary hr {
    border-top-color: #ebebee; }
  .alert-secondary .alert-link {
    color: #606161; }

.alert-success {
  color: #004a46;
  background-color: #cce9e7;
  border-color: #b8e0dd; }
  .alert-success hr {
    border-top-color: #a6d8d5; }
  .alert-success .alert-link {
    color: #001716; }

.alert-info {
  color: #313078;
  background-color: #dfdefa;
  border-color: #d2d1f8; }
  .alert-info hr {
    border-top-color: #bcbbf5; }
  .alert-info .alert-link {
    color: #222154; }

.alert-warning {
  color: #82490b;
  background-color: #fee8d0;
  border-color: #fedfbe; }
  .alert-warning hr {
    border-top-color: #fed3a5; }
  .alert-warning .alert-link {
    color: #532f07; }

.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7; }
  .alert-danger hr {
    border-top-color: #fbb3af; }
  .alert-danger .alert-link {
    color: #551713; }

.alert-light {
  color: #78797a;
  background-color: #fafafb;
  border-color: #f8f9f9; }
  .alert-light hr {
    border-top-color: #eaeded; }
  .alert-light .alert-link {
    color: #5f6060; }

.alert-dark {
  color: #050b16;
  background-color: #ced0d5;
  border-color: #babec4; }
  .alert-dark hr {
    border-top-color: #acb1b8; }
  .alert-dark .alert-link {
    color: black; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: -webkit-flex;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e6e8ea;
  border-radius: 0.25rem; }

.progress-bar {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5E5CE6;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start; }

.media-body {
  -webkit-flex: 1 1;
          flex: 1 1; }

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f3f3f4; }
  .list-group-item-action:active {
    color: #0A162B;
    background-color: #e6e8ea; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c7380;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #5E5CE6;
    border-color: #5E5CE6; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  -webkit-flex-direction: row;
          flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #313078;
  background-color: #d2d1f8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #313078;
    background-color: #bcbbf5; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #313078;
    border-color: #313078; }

.list-group-item-secondary {
  color: #797a7b;
  background-color: #f9f9fa; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #797a7b;
    background-color: #ebebee; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #797a7b;
    border-color: #797a7b; }

.list-group-item-success {
  color: #004a46;
  background-color: #b8e0dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #004a46;
    background-color: #a6d8d5; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #004a46;
    border-color: #004a46; }

.list-group-item-info {
  color: #313078;
  background-color: #d2d1f8; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #313078;
    background-color: #bcbbf5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #313078;
    border-color: #313078; }

.list-group-item-warning {
  color: #82490b;
  background-color: #fedfbe; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #82490b;
    background-color: #fed3a5; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #82490b;
    border-color: #82490b; }

.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f231c;
    background-color: #fbb3af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f231c;
    border-color: #7f231c; }

.list-group-item-light {
  color: #78797a;
  background-color: #f8f9f9; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #78797a;
    background-color: #eaeded; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #78797a;
    border-color: #78797a; }

.list-group-item-dark {
  color: #050b16;
  background-color: #babec4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #050b16;
    background-color: #acb1b8; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #050b16;
    border-color: #050b16; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c7380;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: -webkit-flex;
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #ced0d5;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #ced0d5;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 400px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1140px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1400px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #3B4456; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #3B4456; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #3B4456; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #3B4456; }

.tooltip-inner {
  max-width: 300px;
  padding: 0.5rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #3B4456;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #0A162B; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    -webkit-flex: 0 1 auto;
            flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #5E5CE6 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #3230df !important; }

.bg-secondary {
  background-color: #E9EBED !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cdd2d6 !important; }

.bg-success {
  background-color: #008F86 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #005c56 !important; }

.bg-info {
  background-color: #5E5CE6 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3230df !important; }

.bg-warning {
  background-color: #FA8C16 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d87205 !important; }

.bg-danger {
  background-color: #F44336 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important; }

.bg-light {
  background-color: #e6e8ea !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #cacfd3 !important; }

.bg-dark {
  background-color: #0A162B !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #000102 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #ced0d5 !important; }

.border-top {
  border-top: 1px solid #ced0d5 !important; }

.border-right {
  border-right: 1px solid #ced0d5 !important; }

.border-bottom {
  border-bottom: 1px solid #ced0d5 !important; }

.border-left {
  border-left: 1px solid #ced0d5 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #5E5CE6 !important; }

.border-secondary {
  border-color: #E9EBED !important; }

.border-success {
  border-color: #008F86 !important; }

.border-info {
  border-color: #5E5CE6 !important; }

.border-warning {
  border-color: #FA8C16 !important; }

.border-danger {
  border-color: #F44336 !important; }

.border-light {
  border-color: #e6e8ea !important; }

.border-dark {
  border-color: #0A162B !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-flex !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media (min-width: 1600px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-xxl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important; }

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important; }

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important; }

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important; }

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important; }

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important; }

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important; }

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important; }

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important; }

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important; }

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important; }

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important; }

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important; }

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important; }

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important; }

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

@media (min-width: 1600px) {
  .flex-xxl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xxl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xxl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important; }
  .justify-content-xxl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important; }
  .justify-content-xxl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important; }
  .justify-content-xxl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important; }
  .justify-content-xxl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important; }
  .align-items-xxl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important; }
  .align-items-xxl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important; }
  .align-items-xxl-center {
    -webkit-align-items: center !important;
            align-items: center !important; }
  .align-items-xxl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important; }
  .align-items-xxl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important; }
  .align-content-xxl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important; }
  .align-content-xxl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important; }
  .align-content-xxl-center {
    -webkit-align-content: center !important;
            align-content: center !important; }
  .align-content-xxl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important; }
  .align-content-xxl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important; }
  .align-content-xxl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important; }
  .align-self-xxl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important; }
  .align-self-xxl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important; }
  .align-self-xxl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important; }
  .align-self-xxl-center {
    -webkit-align-self: center !important;
            align-self: center !important; }
  .align-self-xxl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important; }
  .align-self-xxl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1600px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.125rem 1rem rgba(59, 68, 86, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1600px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -3rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1600px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 500 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #5E5CE6 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #2321d5 !important; }

.text-secondary {
  color: #E9EBED !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #bfc5cb !important; }

.text-success {
  color: #008F86 !important; }

a.text-success:hover, a.text-success:focus {
  color: #00433e !important; }

.text-info {
  color: #5E5CE6 !important; }

a.text-info:hover, a.text-info:focus {
  color: #2321d5 !important; }

.text-warning {
  color: #FA8C16 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #bf6504 !important; }

.text-danger {
  color: #F44336 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d2190b !important; }

.text-light {
  color: #e6e8ea !important; }

a.text-light:hover, a.text-light:focus {
  color: #bcc2c7 !important; }

.text-dark {
  color: #0A162B !important; }

a.text-dark:hover, a.text-dark:focus {
  color: black !important; }

.text-body {
  color: #0A162B !important; }

.text-muted {
  color: #6c7380 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ced0d5 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #ced0d5; }
  .table .thead-dark th {
    color: inherit;
    border-color: #ced0d5; } }

body {
  overflow-x: hidden;
  overflow-y: scroll;
  color: #3B4456; }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  padding-right: 0;
  padding-left: 0; }

.page {
  padding-bottom: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: calc(100vh - 150px); }
  .page > .spinner {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    justify-self: center;
    margin: auto; }

.grid-center {
  display: grid;
  place-items: center; }

h1, .h1 {
  font-weight: 300;
  font-size: 2.625rem;
  margin-bottom: 1rem;
  color: #0A162B; }

h2, .h2 {
  font-weight: 500;
  margin-bottom: .25rem;
  color: #0A162B; }

h3, .h3 {
  font-weight: 700;
  color: #0A162B; }

h4, .h4 {
  font-weight: 400;
  color: #0A162B; }

h5, .h5 {
  font-weight: 700; }

h6, .h6 {
  font-weight: 500; }

.btn {
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: .9375rem;
  position: relative; }
  .btn[aria-label]:hover:after {
    content: attr(aria-label);
    position: absolute;
    top: 110%;
    right: 0;
    padding: 0.5rem 0.5rem;
    background: #3B4456;
    color: #fff;
    max-width: 300px;
    border-radius: .25rem;
    overflow: hidden;
    z-index: 1;
    text-transform: none;
    font-weight: 300; }
    .btn[aria-label]:hover:after * {
      background: transparent !important;
      border: none !important;
      margin: 0 !important;
      text-transform: none;
      color: #fff !important; }
  .btn:hover {
    color: #9DA2AA;
    text-decoration: none; }
  .btn.btn-sm, .btn-group-sm > .btn {
    padding: .4375rem .75rem; }
  .btn.btn-primary {
    background: #5E5CE6;
    color: #fff;
    border-color: #5E5CE6; }
    .btn.btn-primary:hover {
      background: #5E5CE6;
      color: #fff;
      box-shadow: 0 4px 10px rgba(94, 92, 230, 0.4); }
    .btn.btn-primary:focus, .btn.btn-primary.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-primary.disabled, .btn.btn-primary:disabled {
      background: #f3f3f4;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-primary.disabled:hover, .btn.btn-primary:disabled:hover {
        background: #f3f3f4;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-primary:not(:disabled):not(.disabled):active, .btn.btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-primary.dropdown-toggle {
      background: #3332AA;
      border-color: #3332AA;
      box-shadow: none; }
      .btn.btn-primary:not(:disabled):not(.disabled):active:focus, .btn.btn-primary:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-primary.dropdown-toggle:focus {
        background: #3332AA;
        border-color: #3332AA;
        color: #fff;
        box-shadow: none; }
  .btn.btn-secondary {
    background: #E4F4FF;
    color: #5E5CE6;
    border-color: #E4F4FF; }
    .btn.btn-secondary:hover {
      background: #E4F4FF;
      color: #5E5CE6;
      box-shadow: 0 4px 10px rgba(228, 244, 255, 0.4); }
    .btn.btn-secondary:focus, .btn.btn-secondary.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-secondary.disabled, .btn.btn-secondary:disabled {
      background: #f3f3f4;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-secondary.disabled:hover, .btn.btn-secondary:disabled:hover {
        background: #f3f3f4;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-secondary:not(:disabled):not(.disabled):active, .btn.btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-secondary.dropdown-toggle {
      background: #BFE2FF;
      border-color: #BFE2FF;
      box-shadow: none; }
      .btn.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn.btn-secondary:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-secondary.dropdown-toggle:focus {
        background: #BFE2FF;
        border-color: #BFE2FF;
        color: #5E5CE6;
        box-shadow: none; }
  .btn.btn-success {
    background: #008F86;
    color: #fff;
    border-color: #008F86; }
    .btn.btn-success:hover {
      background: #008F86;
      color: #fff;
      box-shadow: 0 4px 10px rgba(0, 143, 134, 0.4); }
    .btn.btn-success:focus, .btn.btn-success.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-success.disabled, .btn.btn-success:disabled {
      background: #f3f3f4;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-success.disabled:hover, .btn.btn-success:disabled:hover {
        background: #f3f3f4;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-success:not(:disabled):not(.disabled):active, .btn.btn-success:not(:disabled):not(.disabled).active,
    .show > .btn.btn-success.dropdown-toggle {
      background: #076349;
      border-color: #076349;
      box-shadow: none; }
      .btn.btn-success:not(:disabled):not(.disabled):active:focus, .btn.btn-success:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-success.dropdown-toggle:focus {
        background: #076349;
        border-color: #076349;
        color: #fff;
        box-shadow: none; }
  .btn.btn-info {
    background: #5E5CE6;
    color: #fff;
    border-color: #5E5CE6; }
    .btn.btn-info:hover {
      background: #5E5CE6;
      color: #fff;
      box-shadow: 0 4px 10px rgba(94, 92, 230, 0.4); }
    .btn.btn-info:focus, .btn.btn-info.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-info.disabled, .btn.btn-info:disabled {
      background: #f3f3f4;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-info.disabled:hover, .btn.btn-info:disabled:hover {
        background: #f3f3f4;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-info:not(:disabled):not(.disabled):active, .btn.btn-info:not(:disabled):not(.disabled).active,
    .show > .btn.btn-info.dropdown-toggle {
      background: #B094FF;
      border-color: #B094FF;
      box-shadow: none; }
      .btn.btn-info:not(:disabled):not(.disabled):active:focus, .btn.btn-info:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-info.dropdown-toggle:focus {
        background: #B094FF;
        border-color: #B094FF;
        color: #fff;
        box-shadow: none; }
  .btn.btn-warning {
    background: #FA8C16;
    color: #fff;
    border-color: #FA8C16; }
    .btn.btn-warning:hover {
      background: #FA8C16;
      color: #fff;
      box-shadow: 0 4px 10px rgba(250, 140, 22, 0.4); }
    .btn.btn-warning:focus, .btn.btn-warning.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-warning.disabled, .btn.btn-warning:disabled {
      background: #f3f3f4;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-warning.disabled:hover, .btn.btn-warning:disabled:hover {
        background: #f3f3f4;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-warning:not(:disabled):not(.disabled):active, .btn.btn-warning:not(:disabled):not(.disabled).active,
    .show > .btn.btn-warning.dropdown-toggle {
      background: #ED6D13;
      border-color: #ED6D13;
      box-shadow: none; }
      .btn.btn-warning:not(:disabled):not(.disabled):active:focus, .btn.btn-warning:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-warning.dropdown-toggle:focus {
        background: #ED6D13;
        border-color: #ED6D13;
        color: #fff;
        box-shadow: none; }
  .btn.btn-danger {
    background: #F44336;
    color: #fff;
    border-color: #F44336; }
    .btn.btn-danger:hover {
      background: #F44336;
      color: #fff;
      box-shadow: 0 4px 10px rgba(244, 67, 54, 0.4); }
    .btn.btn-danger:focus, .btn.btn-danger.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-danger.disabled, .btn.btn-danger:disabled {
      background: #f3f3f4;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-danger.disabled:hover, .btn.btn-danger:disabled:hover {
        background: #f3f3f4;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-danger:not(:disabled):not(.disabled):active, .btn.btn-danger:not(:disabled):not(.disabled).active,
    .show > .btn.btn-danger.dropdown-toggle {
      background: #E12B2B;
      border-color: #E12B2B;
      box-shadow: none; }
      .btn.btn-danger:not(:disabled):not(.disabled):active:focus, .btn.btn-danger:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-danger.dropdown-toggle:focus {
        background: #E12B2B;
        border-color: #E12B2B;
        color: #fff;
        box-shadow: none; }
  .btn.btn-light {
    background: #e6e8ea;
    color: #5E5CE6;
    border-color: #e6e8ea; }
    .btn.btn-light:hover {
      background: #e6e8ea;
      color: #5E5CE6;
      box-shadow: 0 4px 10px rgba(230, 232, 234, 0.4); }
    .btn.btn-light:focus, .btn.btn-light.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-light.disabled, .btn.btn-light:disabled {
      background: #f3f3f4;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-light.disabled:hover, .btn.btn-light:disabled:hover {
        background: #f3f3f4;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-light:not(:disabled):not(.disabled):active, .btn.btn-light:not(:disabled):not(.disabled).active,
    .show > .btn.btn-light.dropdown-toggle {
      background: #f3f3f4;
      border-color: #f3f3f4;
      box-shadow: none; }
      .btn.btn-light:not(:disabled):not(.disabled):active:focus, .btn.btn-light:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-light.dropdown-toggle:focus {
        background: #f3f3f4;
        border-color: #f3f3f4;
        color: #5E5CE6;
        box-shadow: none; }
  .btn.btn-dark {
    background: #0A162B;
    color: #fff;
    border-color: #0A162B; }
    .btn.btn-dark:hover {
      background: #0A162B;
      color: #fff;
      box-shadow: 0 4px 10px rgba(10, 22, 43, 0.4); }
    .btn.btn-dark:focus, .btn.btn-dark.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-dark.disabled, .btn.btn-dark:disabled {
      background: #f3f3f4;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-dark.disabled:hover, .btn.btn-dark:disabled:hover {
        background: #f3f3f4;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-dark:not(:disabled):not(.disabled):active, .btn.btn-dark:not(:disabled):not(.disabled).active,
    .show > .btn.btn-dark.dropdown-toggle {
      background: #6c7380;
      border-color: #6c7380;
      box-shadow: none; }
      .btn.btn-dark:not(:disabled):not(.disabled):active:focus, .btn.btn-dark:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-dark.dropdown-toggle:focus {
        background: #6c7380;
        border-color: #6c7380;
        color: #fff;
        box-shadow: none; }
  .btn.btn-outline-primary {
    background: transparent;
    color: #5E5CE6;
    border-color: #5E5CE6; }
    .btn.btn-outline-primary:hover {
      background: #5E5CE6;
      color: #fff;
      box-shadow: 0 4px 10px rgba(94, 92, 230, 0.4); }
    .btn.btn-outline-primary:focus, .btn.btn-outline-primary.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-outline-primary.disabled, .btn.btn-outline-primary:disabled {
      background: transparent;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-outline-primary.disabled:hover, .btn.btn-outline-primary:disabled:hover {
        background: transparent;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-outline-primary:not(:disabled):not(.disabled):active, .btn.btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-outline-primary.dropdown-toggle {
      background: #5E5CE6;
      border-color: #5E5CE6;
      color: #fff;
      box-shadow: none; }
      .btn.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-outline-primary.dropdown-toggle:focus {
        background: #5E5CE6;
        border-color: #5E5CE6;
        color: #fff;
        box-shadow: none; }
  .btn.btn-outline-secondary {
    background: transparent;
    color: #E9EBED;
    border-color: #E9EBED; }
    .btn.btn-outline-secondary:hover {
      background: #E9EBED;
      color: #5E5CE6;
      box-shadow: 0 4px 10px rgba(233, 235, 237, 0.4); }
    .btn.btn-outline-secondary:focus, .btn.btn-outline-secondary.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-outline-secondary.disabled, .btn.btn-outline-secondary:disabled {
      background: transparent;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-outline-secondary.disabled:hover, .btn.btn-outline-secondary:disabled:hover {
        background: transparent;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active,
    .show > .btn.btn-outline-secondary.dropdown-toggle {
      background: #E9EBED;
      border-color: #E9EBED;
      color: #5E5CE6;
      box-shadow: none; }
      .btn.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-outline-secondary.dropdown-toggle:focus {
        background: #E9EBED;
        border-color: #E9EBED;
        color: #5E5CE6;
        box-shadow: none; }
  .btn.btn-outline-success {
    background: transparent;
    color: #008F86;
    border-color: #008F86; }
    .btn.btn-outline-success:hover {
      background: #008F86;
      color: #fff;
      box-shadow: 0 4px 10px rgba(0, 143, 134, 0.4); }
    .btn.btn-outline-success:focus, .btn.btn-outline-success.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-outline-success.disabled, .btn.btn-outline-success:disabled {
      background: transparent;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-outline-success.disabled:hover, .btn.btn-outline-success:disabled:hover {
        background: transparent;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-outline-success:not(:disabled):not(.disabled):active, .btn.btn-outline-success:not(:disabled):not(.disabled).active,
    .show > .btn.btn-outline-success.dropdown-toggle {
      background: #008F86;
      border-color: #008F86;
      color: #fff;
      box-shadow: none; }
      .btn.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-success:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-outline-success.dropdown-toggle:focus {
        background: #008F86;
        border-color: #008F86;
        color: #fff;
        box-shadow: none; }
  .btn.btn-outline-info {
    background: transparent;
    color: #5E5CE6;
    border-color: #5E5CE6; }
    .btn.btn-outline-info:hover {
      background: #5E5CE6;
      color: #fff;
      box-shadow: 0 4px 10px rgba(94, 92, 230, 0.4); }
    .btn.btn-outline-info:focus, .btn.btn-outline-info.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-outline-info.disabled, .btn.btn-outline-info:disabled {
      background: transparent;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-outline-info.disabled:hover, .btn.btn-outline-info:disabled:hover {
        background: transparent;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-outline-info:not(:disabled):not(.disabled):active, .btn.btn-outline-info:not(:disabled):not(.disabled).active,
    .show > .btn.btn-outline-info.dropdown-toggle {
      background: #5E5CE6;
      border-color: #5E5CE6;
      color: #fff;
      box-shadow: none; }
      .btn.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-info:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-outline-info.dropdown-toggle:focus {
        background: #5E5CE6;
        border-color: #5E5CE6;
        color: #fff;
        box-shadow: none; }
  .btn.btn-outline-warning {
    background: transparent;
    color: #FA8C16;
    border-color: #FA8C16; }
    .btn.btn-outline-warning:hover {
      background: #FA8C16;
      color: #fff;
      box-shadow: 0 4px 10px rgba(250, 140, 22, 0.4); }
    .btn.btn-outline-warning:focus, .btn.btn-outline-warning.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-outline-warning.disabled, .btn.btn-outline-warning:disabled {
      background: transparent;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-outline-warning.disabled:hover, .btn.btn-outline-warning:disabled:hover {
        background: transparent;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-outline-warning:not(:disabled):not(.disabled):active, .btn.btn-outline-warning:not(:disabled):not(.disabled).active,
    .show > .btn.btn-outline-warning.dropdown-toggle {
      background: #FA8C16;
      border-color: #FA8C16;
      color: #fff;
      box-shadow: none; }
      .btn.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-outline-warning.dropdown-toggle:focus {
        background: #FA8C16;
        border-color: #FA8C16;
        color: #fff;
        box-shadow: none; }
  .btn.btn-outline-danger {
    background: transparent;
    color: #F44336;
    border-color: #F44336; }
    .btn.btn-outline-danger:hover {
      background: #F44336;
      color: #fff;
      box-shadow: 0 4px 10px rgba(244, 67, 54, 0.4); }
    .btn.btn-outline-danger:focus, .btn.btn-outline-danger.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-outline-danger.disabled, .btn.btn-outline-danger:disabled {
      background: transparent;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-outline-danger.disabled:hover, .btn.btn-outline-danger:disabled:hover {
        background: transparent;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-outline-danger:not(:disabled):not(.disabled):active, .btn.btn-outline-danger:not(:disabled):not(.disabled).active,
    .show > .btn.btn-outline-danger.dropdown-toggle {
      background: #F44336;
      border-color: #F44336;
      color: #fff;
      box-shadow: none; }
      .btn.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-outline-danger.dropdown-toggle:focus {
        background: #F44336;
        border-color: #F44336;
        color: #fff;
        box-shadow: none; }
  .btn.btn-outline-light {
    background: transparent;
    color: #e6e8ea;
    border-color: #e6e8ea;
    color: #5E5CE6; }
    .btn.btn-outline-light:hover {
      background: #e6e8ea;
      color: #5E5CE6;
      box-shadow: 0 4px 10px rgba(230, 232, 234, 0.4); }
    .btn.btn-outline-light:focus, .btn.btn-outline-light.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-outline-light.disabled, .btn.btn-outline-light:disabled {
      background: transparent;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-outline-light.disabled:hover, .btn.btn-outline-light:disabled:hover {
        background: transparent;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-outline-light:not(:disabled):not(.disabled):active, .btn.btn-outline-light:not(:disabled):not(.disabled).active,
    .show > .btn.btn-outline-light.dropdown-toggle {
      background: #e6e8ea;
      border-color: #e6e8ea;
      color: #5E5CE6;
      box-shadow: none; }
      .btn.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-light:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-outline-light.dropdown-toggle:focus {
        background: #e6e8ea;
        border-color: #e6e8ea;
        color: #5E5CE6;
        box-shadow: none; }
  .btn.btn-outline-dark {
    background: transparent;
    color: #0A162B;
    border-color: #0A162B; }
    .btn.btn-outline-dark:hover {
      background: #0A162B;
      color: #fff;
      box-shadow: 0 4px 10px rgba(10, 22, 43, 0.4); }
    .btn.btn-outline-dark:focus, .btn.btn-outline-dark.focus {
      outline: 0;
      box-shadow: none; }
    .btn.btn-outline-dark.disabled, .btn.btn-outline-dark:disabled {
      background: transparent;
      color: #ced0d5;
      border-color: #f3f3f4; }
      .btn.btn-outline-dark.disabled:hover, .btn.btn-outline-dark:disabled:hover {
        background: transparent;
        color: #ced0d5;
        border-color: #f3f3f4;
        box-shadow: none; }
    .btn.btn-outline-dark:not(:disabled):not(.disabled):active, .btn.btn-outline-dark:not(:disabled):not(.disabled).active,
    .show > .btn.btn-outline-dark.dropdown-toggle {
      background: #0A162B;
      border-color: #0A162B;
      color: #fff;
      box-shadow: none; }
      .btn.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
      .show > .btn.btn-outline-dark.dropdown-toggle:focus {
        background: #0A162B;
        border-color: #0A162B;
        color: #fff;
        box-shadow: none; }
  .btn.refresh-button {
    padding: .5rem .75rem; }
  .btn.short-button {
    padding: .5rem 1rem; }
  .btn .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

h2 + .refresh-button {
  margin-left: 1rem;
  margin-top: -.5rem; }

a:hover, a:active, a:focus, a.active, .btn-link:hover, .btn-link:active, .btn-link:focus, .btn-link.active {
  text-decoration: none;
  outline: none; }

.action-wrapper .sub-menu {
  font-size: .875rem;
  line-height: 1; }

.action-wrapper .nav-link {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0; }
  .action-wrapper .nav-link .icon-item {
    margin-right: .25rem; }

label .required {
  display: inline-block;
  color: #F44336;
  margin-left: .25rem;
  -webkit-transform: scale(1.25);
          transform: scale(1.25); }

.form-control {
  border-radius: 0.5rem; }
  .form-control:hover {
    border-color: #ced0d5; }
  .form-control:focus {
    border-color: #5E5CE6;
    box-shadow: none; }
    .form-control:focus label {
      color: #5E5CE6; }
  .form-control:disabled, .form-control.form-control[readonly], .form-control:disabled:hover, .form-control.form-control[readonly]:hover {
    border-color: #CED0D5;
    cursor: default; }

.form-control {
  height: 3rem;
  line-height: 1.2;
  padding: .5rem .75rem; }
  .form-control.form-control-sm {
    height: 2rem;
    font-size: 0.875rem; }

.col-form-label-sm,
.form-control-sm {
  padding: .25rem .5rem; }

.custom-select {
  background: none; }

.input-wrapper {
  position: relative; }

.shifted {
  position: relative; }
  .shifted .form-control {
    background: #fff; }
    .shifted .form-control:disabled:focus, .shifted .form-control[readonly]:focus {
      border-color: #E9EBED; }
  .shifted .form-label {
    position: absolute;
    font-size: .625rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    top: -.45rem;
    left: .5rem;
    background: #fff;
    padding: .2rem .4rem .15rem;
    font-weight: 400;
    z-index: 5; }
    .shifted .form-label.custom-control-label {
      position: relative;
      top: auto;
      top: initial;
      left: auto;
      left: initial;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }

.inline-checkbox-group .form-label {
  color: #3b4456; }

.custom-checkbox .custom-control-label {
  cursor: pointer; }
  .custom-checkbox .custom-control-label:before {
    border-radius: .1rem; }

.custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  box-shadow: none; }

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none; }

.custom-dropdown {
  padding: 1rem 0;
  position: absolute;
  min-width: 100%;
  z-index: 10;
  max-height: 21rem;
  overflow-x: auto; }

.dropdown-item {
  font-weight: 300; }

.dropdown-menu {
  min-width: 100%;
  border: none;
  box-shadow: 0 20px 30px rgba(10, 22, 43, 0.08); }

.panel .heading {
  margin-bottom: 1rem; }

.card {
  border: 1px solid #CED0D5;
  border-radius: 0.625rem;
  overflow: hidden; }
  .card .heading a {
    color: #0A162B;
    font-weight: 500; }
    .card .heading a:hover {
      color: #5E5CE6; }
  .card .subtitle {
    color: #6c7380; }
  .card .card-header {
    background: #fff;
    border-bottom-color: #f3f3f4;
    padding: .75rem 1rem; }
  .card .card-body {
    padding: 1rem; }
  .card.card-item {
    margin-bottom: 2rem; }
  .card-grid .card.card-item {
    margin-bottom: 0; }

.nav-tabs {
  margin-bottom: 2rem;
  border-bottom: transparent;
  padding-bottom: .125rem;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
  overflow-y: visible; }
  .nav-tabs:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background: #E9EBED;
    -webkit-align-self: flex-end;
            align-self: flex-end;
    margin-bottom: -1px; }
  .nav-tabs::-webkit-scrollbar {
    height: 0 !important; }
  .nav-tabs .nav-item.show .nav-link {
    border-bottom-color: #E9EBED #E9EBED #fff; }
  .nav-tabs .nav-link {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.75rem;
    padding: .6rem 1.25rem;
    border-radius: 4px 4px 0 0;
    border-bottom-color: #E9EBED;
    color: #3B4456;
    white-space: nowrap; }
    .nav-tabs .nav-link.disabled {
      border-bottom-color: #E9EBED; }
    .nav-tabs .nav-link:focus {
      border-color: transparent;
      outline: none; }
    .nav-tabs .nav-link.active {
      border-color: #E9EBED #E9EBED #fff; }

table.table {
  color: inherit;
  line-height: 1.3;
  width: 100%; }
  table.table thead th {
    border: none;
    font-size: .75rem;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  table.table tr:first-child td {
    border-top-color: transparent; }
  table.table td {
    font-size: 0.875rem;
    font-weight: 300;
    vertical-align: middle;
    border-top-color: #E9EBED;
    white-space: nowrap;
    padding: 0 1rem;
    height: 56px;
    overflow: hidden;
    text-overflow: ellipsis; }
    table.table td .response-title {
      display: none; }
    table.table td.actions {
      overflow: visible; }
    table.table td .buttons-wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      table.table td .buttons-wrapper .btn + .btn {
        margin-left: .5rem; }
    table.table td .custom-checkbox {
      margin-bottom: 0; }
  table.table .custom-dropdown {
    margin-left: auto; }
  table.table .form-group {
    margin: 0; }

table.table-flex thead {
  left: -999999999px;
  position: absolute;
  visibility: hidden; }

table.table-flex tbody tr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  border: 1px solid #e6e8ea;
  border-bottom-width: .5rem; }
  table.table-flex tbody tr:last-child {
    border-bottom: none; }
  table.table-flex tbody tr td {
    font-size: .8125rem;
    padding: 2rem .5rem .5rem;
    position: relative;
    width: 100%;
    border: solid #f3f3f4;
    border-width: 1px 0; }
    table.table-flex tbody tr td:last-child {
      text-align: left; }
    table.table-flex tbody tr td .response-title {
      color: #0A162B;
      font-size: .75rem;
      font-weight: 500;
      text-transform: uppercase;
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      padding: 0.25rem;
      border-top: none;
      border-bottom: 1px solid #E9EBED; }

.badge {
  text-transform: uppercase;
  padding: 0.3125rem 0.5rem;
  font-weight: 900;
  border-radius: 2rem; }
  .badge.badge-primary {
    background: #C5D1FB;
    color: #3332AA; }
  .badge.badge-secondary {
    background: #E9EBED;
    color: #3B4456; }
  .badge.badge-warning {
    background: #FCF3D7;
    color: #ED6D13; }
  .badge.badge-danger {
    background: #FFEFEF;
    color: #E12B2B; }
  .badge.badge-info {
    background: #F2EDFF;
    color: #3332AA; }
  .badge.badge-success {
    background: #D9F3EB;
    color: #076349; }
  .badge.badge-dark {
    background: #f3f3f4;
    color: #0A162B; }

@media (min-width: 576px) {
  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding-right: 15px;
    padding-left: 15px; }
  table.table-flex tbody tr td {
    width: 50%; } }

@media (min-width: 992px) {
  table.table-flex tbody tr td {
    border: none; } }

@media (min-width: 1365px) {
  table.table-flex {
    color: inherit;
    line-height: 1.3; }
    table.table-flex thead {
      position: relative;
      left: 0;
      visibility: visible; }
      table.table-flex thead th {
        vertical-align: middle;
        border-top: none;
        font-size: .75rem;
        font-weight: 500;
        text-transform: uppercase;
        white-space: nowrap; }
        table.table-flex thead th:last-child {
          text-align: right; }
    table.table-flex tbody {
      border: none; }
      table.table-flex tbody tr {
        display: table-row;
        border-bottom-width: 1px;
        border-left: none;
        border-right: none; }
        table.table-flex tbody tr td {
          display: table-cell;
          font-size: 0.875rem;
          font-weight: 300;
          border-top-color: #f3f3f4;
          vertical-align: middle;
          white-space: nowrap;
          padding: 1.5rem 0.75rem;
          width: auto; }
          table.table-flex tbody tr td:last-child {
            text-align: right; }
          table.table-flex tbody tr td .response-title {
            display: none; } }

.alert-danger {
  background: #FFEFEF;
  color: #F44336;
  border: 1px solid #F44336; }

.semi-transparent {
  opacity: .1;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out; }

.no-transparent {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out; }

.big-shadow {
  box-shadow: 0 20px 30px rgba(10, 22, 43, 0.08);
  border-radius: 4px; }

.wrapper {
  min-height: 100vh;
  position: relative; }

.filter-layout {
  margin-bottom: 4rem; }
  .filter-layout .filter-form {
    max-height: 1000px;
    opacity: 1;
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
  .filter-layout .footer-buttons {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
  .filter-layout .filter-toggler {
    margin-left: auto;
    margin-bottom: 2rem;
    -webkit-transition: all .3s ease;
    transition: all .3s ease; }
    @media (min-width: 1200px) {
      .filter-layout .filter-toggler {
        display: none; } }
  .filter-layout.hidden-filter {
    margin-bottom: 0; }
    .filter-layout.hidden-filter .filter-form {
      max-height: 0;
      opacity: 0;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
      @media (min-width: 1200px) {
        .filter-layout.hidden-filter .filter-form {
          max-height: 1000px;
          opacity: 1; } }
    .filter-layout.hidden-filter .filter-toggler {
      margin-left: auto;
      margin-bottom: 0;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }

.icon-item {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  .icon-item.boxed {
    border-radius: 4px;
    background: transparent;
    border: 1px solid;
    min-width: 1.25rem;
    min-height: 1.25rem; }

.custom-button {
  display: -webkit-flex;
  display: flex;
  position: relative;
  border-radius: 2rem; }
  .custom-button span {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
  .custom-button.btn-outline-primary {
    border-color: #CED0D5;
    background: #fff;
    color: #0A162B; }
    .custom-button.btn-outline-primary:hover {
      border-color: #9DA2AA;
      background: #fff;
      color: #5E5CE6;
      box-shadow: none; }
    .custom-button.btn-outline-primary:not(:disabled):not(.disabled):active, .custom-button.btn-outline-primary:not(:disabled):not(.disabled):focus, .custom-button.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .custom-button.btn-outline-primary:not(:disabled):not(.disabled).active {
      border-color: #9DA2AA;
      background: #F8F8F9; }
  .custom-button.btn-black {
    border-color: #0A162B;
    background: #0A162B;
    color: #fff; }
    .custom-button.btn-black:hover {
      box-shadow: 0 4px 10px rgba(2, 1, 24, 0.4); }
    .custom-button.btn-black:not(:disabled):not(.disabled):active, .custom-button.btn-black:not(:disabled):not(.disabled):focus, .custom-button.btn-black:not(:disabled):not(.disabled):active:focus, .custom-button.btn-black:not(:disabled):not(.disabled).active {
      border-color: #0A162B;
      background: #0A162B;
      box-shadow: none; }
  .custom-button.btn.icon-left {
    padding: 0.8125rem 0.9375rem 0.8125rem 0.6875rem; }
    .custom-button.btn.icon-left .icon-wrapper {
      margin-right: 0.5rem; }
    .custom-button.btn.icon-left.is-link {
      padding: 0; }
    .custom-button.btn.icon-left.btn-sm {
      padding: 0.3125rem 0.6875rem 0.3125rem 0.4375rem; }
      .custom-button.btn.icon-left.btn-sm .icon-wrapper {
        margin-right: 0.375rem; }
  .custom-button.btn.icon-left.icon-only {
    padding: 0.8125rem; }
    .custom-button.btn.icon-left.icon-only .icon-wrapper {
      margin: 0; }
    .custom-button.btn.icon-left.icon-only.btn-sm {
      padding: 0.3125rem; }
      .custom-button.btn.icon-left.icon-only.btn-sm .icon-wrapper {
        margin: 0; }
  .custom-button.btn.icon-right {
    padding: 0.8125rem 0.6875rem 0.8125rem 0.9375rem; }
    .custom-button.btn.icon-right .icon-wrapper {
      margin-left: 0.5rem; }
    .custom-button.btn.icon-right.btn-sm {
      padding: 0.3125rem 0.4375rem 0.3125rem 0.6875rem; }
      .custom-button.btn.icon-right.btn-sm .icon-wrapper {
        margin-left: 0.375rem; }
    .custom-button.btn.icon-right.is-link {
      padding: 0; }
  .custom-button.animated-icon:hover {
    box-shadow: none; }
  .custom-button.animated-icon .icon-wrapper {
    -webkit-animation: animated-icon 0.7s linear infinite;
            animation: animated-icon 0.7s linear infinite; }
  .custom-button.is-link {
    text-transform: none;
    font-weight: 300;
    border-radius: 0; }
    .custom-button.is-link:hover {
      color: #3332AA; }

@-webkit-keyframes animated-icon {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes animated-icon {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .custom-button span {
    white-space: nowrap; }

.checkbox-list {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .checkbox-list .checkbox-item {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    font-size: 1rem;
    line-height: 1;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-bottom: 0.125rem; }
    .checkbox-list .checkbox-item.checked:before {
      font-family: 'Material Icons';
      content: '\E834';
      position: absolute;
      font-size: 20px;
      color: #5E5CE6;
      left: 6px; }
    .checkbox-list .checkbox-item:hover {
      cursor: pointer; }
      .checkbox-list .checkbox-item:hover .item-box {
        border-color: #6C7380; }
    .checkbox-list .checkbox-item.active {
      background: #F8F8F9; }
    .checkbox-list .checkbox-item .item-box {
      width: 1rem;
      height: 1rem;
      border: 1px solid #E9EBED;
      border-radius: 2px;
      margin-right: .5rem; }
    .checkbox-list .checkbox-item .item-label {
      -webkit-flex: 1 1;
              flex: 1 1;
      word-break: break-all; }
  .checkbox-list input[type="text"]:hover {
    cursor: text; }
  .checkbox-list .custom-control {
    display: -webkit-flex;
    display: flex; }
    .checkbox-list .custom-control label {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }

.custom-input-box:hover:not(.focused).with-icon .icon-item {
  color: #9DA2AA; }

.custom-input-box .icon-wrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.custom-input-box.with-icon .icon-item {
  color: #CED0D5; }

.custom-input-box.icon-left .form-control {
  padding-left: 2rem; }
  .custom-input-box.icon-left .form-control.input-sm {
    padding-left: 2rem; }

.custom-input-box.icon-left .icon-wrapper {
  left: .5rem; }

.custom-input-box.icon-right .form-control {
  padding-right: 2rem; }
  .custom-input-box.icon-right .form-control.input-sm {
    padding-right: 2rem; }

.custom-input-box.icon-right .icon-wrapper {
  right: .5rem; }

.custom-input-box.disabled label {
  color: #9DA2AA; }

.custom-input-box.focused .icon-item {
  color: #5E5CE6; }

.custom-input-box .form-control.input-sm {
  font-size: 0.875rem; }

.custom-input-box .input-wrapper + .custom-progress {
  margin-top: .25rem; }

.custom-input-box .sub-message {
  position: absolute;
  top: 105%;
  left: 1rem; }

.sub-message {
  font-size: .75rem;
  line-height: 1; }

.dropdown-checkbox-list .dropdown .form-control {
  cursor: pointer;
  height: 3rem;
  padding-right: 2rem; }
  .dropdown-checkbox-list .dropdown .form-control:disabled, .dropdown-checkbox-list .dropdown .form-control.form-control[readonly], .dropdown-checkbox-list .dropdown .form-control:disabled:hover, .dropdown-checkbox-list .dropdown .form-control.form-control[readonly]:hover {
    border-color: #E9EBED; }

.dropdown-checkbox-list .dropdown > .icon-item {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 0.5rem;
  color: #0A162B;
  cursor: pointer; }

.dropdown-checkbox-list .dropdown .dropdown-menu {
  max-height: 21rem;
  overflow-y: auto; }

.form-inline .custom-select-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .form-inline .custom-select-wrap .form-label {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    font-weight: 400; }

.form-inline label {
  margin-right: .5rem;
  font-weight: 500; }

.dropdown-select-wrap input[type='text'] {
  position: absolute;
  top: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  width: calc(100% - 2rem); }

.dropdown-select-wrap .btn {
  padding-left: .75rem;
  padding-right: .75rem;
  border-radius: 0.5rem; }

.dropdown-select-wrap .dropdown-menu {
  max-height: 21rem;
  overflow-y: auto; }
  .dropdown-select-wrap .dropdown-menu .dropdown-item {
    padding: .5rem 1rem;
    font-size: 1rem; }

.dropdown-select-wrap + .custom-progress {
  margin-top: .25rem; }

.dropdown-select-box.disabled label {
  color: #9DA2AA; }

.dropdown-select-box.disabled .dropdown-select-wrap .icon-item {
  color: #9DA2AA; }

.dropdown-select-box .sub-message {
  position: absolute;
  top: 105%;
  left: 1rem; }

.dropdown-select-wrap {
  position: relative;
  cursor: pointer; }
  .dropdown-select-wrap .custom-select {
    padding-right: 2rem; }
  .dropdown-select-wrap .icon-item {
    pointer-events: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: .5rem;
    color: #0A162B; }

.button-icon {
  padding-left: 1rem;
  padding-right: 1rem; }
  .button-icon.animated-icon {
    background: transparent;
    border-color: transparent; }
    .button-icon.animated-icon:hover {
      background: transparent;
      box-shadow: none; }
    .button-icon.animated-icon .icon-wrapper {
      -webkit-animation: animated-icon 0.7s linear infinite;
              animation: animated-icon 0.7s linear infinite; }

@-webkit-keyframes animated-icon {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes animated-icon {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.date-time-picker .inputs-wrapper {
  display: grid;
  grid-template-columns: 3rem 1fr;
  -webkit-align-items: start;
          align-items: start;
  grid-column-gap: .5rem; }
  .date-time-picker .inputs-wrapper.date-from .custom-input-box:first-of-type input {
    border-color: #5E5CE6; }
  .date-time-picker .inputs-wrapper.date-to .custom-input-box:last-of-type input {
    border-color: #5E5CE6; }
  .date-time-picker .inputs-wrapper.date-to + .calendar-panel {
    right: 0; }
  .date-time-picker .inputs-wrapper.date-range .custom-input-box input {
    border-color: #5E5CE6; }
  .date-time-picker .inputs-wrapper .custom-input-box:last-child {
    grid-column: 1/3; }
  .date-time-picker .inputs-wrapper .custom-input-box.disabled label {
    color: inherit; }
  .date-time-picker .inputs-wrapper .sub-message {
    position: absolute;
    bottom: .5rem;
    left: 4.5rem; }
  @media (min-width: 576px) {
    .date-time-picker .inputs-wrapper {
      grid-template-columns: 6.5rem 1fr 1fr; }
      .date-time-picker .inputs-wrapper .custom-input-box:last-child {
        grid-column: 3/4; } }

.date-time-picker .date-time-pick-calendar {
  height: 20rem; }
  .date-time-picker .date-time-pick-calendar .calendar-navigation {
    display: grid;
    grid-template-columns: 2rem 1fr 2rem;
    margin-bottom: 1rem; }
    .date-time-picker .date-time-pick-calendar .calendar-navigation .month-year {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      font-weight: 500; }
      .date-time-picker .date-time-pick-calendar .calendar-navigation .month-year .month {
        margin-right: 1rem; }
  .date-time-picker .date-time-pick-calendar .week-days,
  .date-time-picker .date-time-pick-calendar .days-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    grid-row-gap: .5rem; }
  .date-time-picker .date-time-pick-calendar .week-days {
    font-size: 0.625rem;
    text-transform: uppercase;
    margin-bottom: .75rem; }
  .date-time-picker .date-time-pick-calendar .days-grid {
    font-size: 0.875rem;
    margin-bottom: .5rem; }
  .date-time-picker .date-time-pick-calendar .day {
    padding: .5rem .75rem;
    border-radius: 0.5rem;
    border: 1px solid transparent; }
    .date-time-picker .date-time-pick-calendar .day:not(.other-month):hover {
      cursor: pointer;
      background: #F8F8F9; }
    .date-time-picker .date-time-pick-calendar .day:not(.selected):hover {
      background: #F8F8F9; }
    .date-time-picker .date-time-pick-calendar .day.other-month {
      color: #CED0D5; }
      .date-time-picker .date-time-pick-calendar .day.other-month:hover {
        cursor: default;
        background-color: transparent;
        background-color: initial; }
    .date-time-picker .date-time-pick-calendar .day.today {
      font-weight: 500;
      border-color: #5E5CE6; }
    .date-time-picker .date-time-pick-calendar .day.forbidden {
      text-decoration: line-through;
      color: #CED0D5; }
      .date-time-picker .date-time-pick-calendar .day.forbidden:hover {
        cursor: default;
        background-color: transparent;
        background-color: initial; }
    .date-time-picker .date-time-pick-calendar .day.in-range {
      background: #F8F8F9;
      border-radius: 0; }
    .date-time-picker .date-time-pick-calendar .day.selected {
      color: #fff;
      background: #5E5CE6;
      border-radius: 0.5rem;
      position: relative; }
      .date-time-picker .date-time-pick-calendar .day.selected.same-date:before {
        content: ' ';
        position: absolute;
        border: 1px solid #fff;
        border-radius: 0.5rem;
        width: 95%;
        height: 95%;
        margin-left: 1px;
        margin-top: 1px;
        top: 0;
        left: 0; }
      .date-time-picker .date-time-pick-calendar .day.selected:hover {
        color: #fff;
        background: #5E5CE6; }
  .date-time-picker .date-time-pick-calendar .icon-item {
    display: -webkit-flex;
    display: flex; }
  .date-time-picker .date-time-pick-calendar .next, .date-time-picker .date-time-pick-calendar .prev {
    padding: .25rem;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .date-time-picker .date-time-pick-calendar .next:hover, .date-time-picker .date-time-pick-calendar .prev:hover {
      cursor: pointer; }

.date-time-picker .calendar-panel {
  position: absolute;
  z-index: 10;
  padding: 1rem;
  top: 4rem;
  box-shadow: 0 20px 30px rgba(10, 22, 43, 0.08); }
  .date-time-picker .calendar-panel .calendar-ranges {
    font-size: 0.875rem;
    color: #000; }
    .date-time-picker .calendar-panel .calendar-ranges .range-item {
      margin-bottom: 1rem;
      cursor: pointer;
      color: #5E5CE6; }
      .date-time-picker .calendar-panel .calendar-ranges .range-item:hover {
        color: #3332AA; }
  .date-time-picker .calendar-panel .calendar-panel-content {
    margin-bottom: 1rem; }
    .date-time-picker .calendar-panel .calendar-panel-content:not(.single) .calendar-prev-month .next-month-nav {
      visibility: hidden; }
    .date-time-picker .calendar-panel .calendar-panel-content:not(.single) .calendar-curr-month .prev-month-nav {
      visibility: hidden; }
  .date-time-picker .calendar-panel .calendar-panel-footer {
    border-top: 1px solid #E9EBED;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: -1rem;
    margin-right: -1rem; }
    .date-time-picker .calendar-panel .calendar-panel-footer .button-wrapper {
      display: -webkit-flex;
      display: flex; }
      .date-time-picker .calendar-panel .calendar-panel-footer .button-wrapper .btn + .btn {
        margin-left: 1rem; }
  @media (min-width: 768px) {
    .date-time-picker .calendar-panel .calendar-panel-content:not(.single) {
      display: grid;
      grid-template-columns: 5rem 1fr 1fr;
      grid-gap: 1rem; } }

.date-time-picker .hidden-input {
  display: none; }


.time-picker {
  width: 7.8125rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 2rem);
  grid-column-gap: 3rem;
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative; }
  .time-picker:before {
    content: '';
    display: block;
    border: 1px solid #E9EBED;
    border-radius: 0.5rem;
    position: absolute;
    margin: 0 auto;
    width: 14rem;
    height: 4.5rem;
    z-index: 1;
    pointer-events: none;
    top: 3.5rem;
    left: -3rem; }
  .time-picker .next, .time-picker .prev {
    padding: 1rem 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .time-picker .next:hover, .time-picker .prev:hover {
      cursor: pointer;
      color: #6C7380; }
  .time-picker input {
    width: 2rem;
    border: none;
    text-align: center;
    padding: 1rem 0;
    font-weight: 300;
    /* Chrome, Safari, Edge, Opera */
    /* Firefox */ }
    .time-picker input:focus {
      outline: none; }
    .time-picker input::-webkit-outer-spin-button, .time-picker input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .time-picker input[type=number] {
      -moz-appearance: textfield; }
  .time-picker .hours,
  .time-picker .minutes,
  .time-picker .seconds {
    text-align: center;
    position: relative; }
  .time-picker .minutes:before, .time-picker .minutes:after {
    content: ':';
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .time-picker .minutes:before {
    left: -1.5rem; }
  .time-picker .minutes:after {
    right: -1.5rem; }

.custom-select-advanced-wrap {
  position: relative;
  cursor: pointer; }
  .custom-select-advanced-wrap .custom-select-advanced {
    padding-right: 2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .custom-select-advanced-wrap .custom-select-advanced span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .custom-select-advanced-wrap .custom-select-advanced ~ .icon-item {
      pointer-events: none;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      right: .5rem;
      color: #0A162B; }
    .custom-select-advanced-wrap .custom-select-advanced ~ .custom-icon {
      position: absolute;
      top: 50%;
      left: .5rem;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.custom-select-advanced-wrap + .custom-progress {
  margin-top: .25rem; }

.custom-select-advanced-box.icon-left .custom-select-advanced {
  padding-left: 2rem; }

.custom-select-advanced-box .dropdown-list.drop-up {
  bottom: 3rem; }

.custom-select-advanced-box .dropdown-list.drop-up-small {
  bottom: 2rem; }

.custom-select-advanced-box .dropdown-list-item {
  padding: .5rem 1rem;
  min-height: 35px; }
  .custom-select-advanced-box .dropdown-list-item:hover, .custom-select-advanced-box .dropdown-list-item.active {
    background: #F8F8F9; }
  .custom-select-advanced-box .dropdown-list-item.selected {
    background: #C5D1FB; }

.custom-select-advanced-box .dropdown-list .custom-input-box {
  margin: 0 1rem; }

.custom-select-advanced-box.disabled label {
  color: #9DA2AA; }

.custom-select-advanced-box.disabled .custom-select-advanced-wrap .icon-item {
  color: #9DA2AA; }

.custom-select-advanced-box .sub-message {
  position: absolute;
  top: 105%;
  left: 1rem; }

.pagination-component {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  -webkit-justify-content: center;
          justify-content: center; }
  .pagination-component .page-switcher {
    display: -webkit-flex;
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem; }
  .pagination-component .page-count-selector .form-group {
    margin-bottom: 0; }
  .pagination-component .no-data {
    margin: auto; }

.no-data {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 20rem; }
  .no-data .image-wrapper {
    margin-bottom: 2rem; }

.page-data-panel {
  margin-bottom: 1rem; }
  .page-data-panel .panel .panel-heading {
    margin-bottom: 1.5rem; }
    .page-data-panel .panel .panel-heading .full-heading-content {
      display: -webkit-flex;
      display: flex; }
      .page-data-panel .panel .panel-heading .full-heading-content > * {
        margin-right: 1.5rem; }
        .page-data-panel .panel .panel-heading .full-heading-content > *:last-child {
          margin-right: 0;
          margin-left: auto; }
      @media (min-width: 1200px) {
        .page-data-panel .panel .panel-heading .full-heading-content .mobile-switcher {
          display: none; } }
    .page-data-panel .panel .panel-heading .mobile-heading-content {
      padding-top: 1rem;
      max-height: 15rem;
      visibility: visible;
      opacity: 1;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
      .page-data-panel .panel .panel-heading .mobile-heading-content.hidden-item {
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
      @media (min-width: 1200px) {
        .page-data-panel .panel .panel-heading .mobile-heading-content {
          display: none; } }
  .page-data-panel.widget.card {
    padding: 1rem; }
  .page-data-panel.widget .panel .panel-heading {
    margin-bottom: 0;
    border: none; }

.request-log-panel table tr th:nth-child(1) {
  width: 3rem; }

.request-log-panel table tr th:nth-child(2) {
  width: 9rem; }

.request-log-panel table tr th:nth-child(3) {
  width: 7rem; }

.request-log-panel table tr th:nth-child(4) {
  width: 7rem; }

.request-log-panel table tr th:nth-child(5) {
  width: 9rem; }

.request-log-panel table tr th:nth-child(6) {
  width: 4rem; }

.request-log-panel table tr th:nth-child(7) {
  width: 10rem; }

.request-log-panel table tr th:nth-child(8) {
  width: 5rem; }

.request-log-panel table tr th:nth-child(9) {
  width: 5rem; }

.request-log-panel .spinner {
  margin: auto; }

@media (min-width: 1200px) {
  .request-log-panel table.table-flex {
    table-layout: auto; }
    .request-log-panel table.table-flex .link-icon .nav-link {
      margin-left: auto; } }

.custom-tooltip {
  position: absolute;
  padding: 0.5rem 0.5rem;
  background: #3B4456;
  color: #fff;
  max-width: 300px;
  border-radius: .25rem;
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
  z-index: 1100; }
  .custom-tooltip span {
    display: block; }
  .custom-tooltip.hide-bgr * {
    background: transparent !important;
    border: none !important;
    margin: 0 !important;
    word-break: break-all;
    color: #fff !important; }

.table-card table {
  table-layout: fixed; }
  .table-card table tr th {
    background: #F8F8F9;
    color: #0A162B;
    padding: 1rem; }
    .table-card table tr th.actions {
      width: 6rem; }
    .table-card table tr th:first-child {
      border-radius: 0.5rem 0 0 0.5rem; }
    .table-card table tr th:last-child {
      border-radius: 0 0.5rem 0.5rem 0; }
  .table-card table tr td.actions {
    text-align: right; }
    .table-card table tr td.actions .nav-link {
      margin-left: auto; }

.table-card.card-view .card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 1.5rem;
  margin-bottom: 2rem; }
  .table-card.card-view .card-grid .card-item {
    height: 100%; }
    .table-card.card-view .card-grid .card-item .card-body {
      height: 100%; }

.table-card.card-view .card-item-section {
  display: -webkit-flex;
  display: flex;
  margin-bottom: .75rem;
  line-height: 0.875rem; }
  .table-card.card-view .card-item-section .badge {
    margin-bottom: .25rem;
    max-width: 100%;
    white-space: normal;
    text-align: left; }
  .table-card.card-view .card-item-section > div:first-child {
    font-size: 0.625rem;
    font-weight: 400;
    text-transform: uppercase;
    width: 6rem;
    margin-right: 1.5rem; }
  .table-card.card-view .card-item-section > div:last-child {
    font-size: 0.875rem;
    width: calc(100% - 7.5rem); }
  .table-card.card-view .card-item-section > div .buttons-wrapper {
    display: -webkit-flex;
    display: flex; }
    .table-card.card-view .card-item-section > div .buttons-wrapper .btn + .btn {
      margin-left: .5rem; }

.table-card.card-view .card-item-label {
  color: #0A162B; }

.table-card.request-log table tr td:first-child {
  font-weight: 500; }

.spinner {
  width: 35px;
  height: 35px;
  position: relative;
  margin: auto; }

.lds-dual-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: inline-block;
  z-index: 10; }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 5px solid;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
            animation: lds-dual-ring 1.2s linear infinite; }
  .lds-dual-ring.light:after {
    border-color: #fff transparent #fff transparent; }
  .lds-dual-ring.dark:after {
    border-color: #7C4DFF transparent #7C4DFF transparent; }

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.small-dialog .modal-dialog .modal-content {
  padding: 1.5rem 2rem;
  min-height: 0; }

.small-dialog .modal-dialog .close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  display: inline-block;
  cursor: pointer; }

.modal-dialog.modal-sm {
  max-width: 400px; }
  .modal-dialog.modal-sm .modal-content {
    box-shadow: 0 2.11781px 28.9412px rgba(10, 22, 43, 0.0725533); }

.modal-dialog button .spinner {
  min-height: auto;
  top: 50%; }

.modal-dialog .modal-content {
  overflow: hidden;
  box-shadow: 0 20px 30px rgba(10, 22, 43, 0.08);
  border-color: transparent;
  min-height: 70vh;
  border-radius: 1rem; }
  .modal-dialog .modal-content > .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.modal-dialog .close-button {
  position: absolute;
  right: 2rem;
  top: 2rem;
  display: inline-block;
  cursor: pointer; }

.modal-backdrop {
  background: #0A162B; }
  .modal-backdrop.show {
    opacity: .5; }

.custom-pre {
  position: relative;
  margin-bottom: 2rem;
  color: #3B4456;
  font-weight: 300; }
  .custom-pre .title {
    font-size: .625rem;
    position: absolute;
    left: .5rem;
    top: -.3rem;
    color: #CED0D5;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    padding-left: .5rem;
    padding-right: .5rem;
    background: #fff; }
  .custom-pre pre {
    padding: 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background: #fff;
    border: 1px solid #CED0D5;
    border-radius: 0.5rem; }

.modal-details {
  height: 90vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .modal-details .modal-details-header {
    padding: 2rem;
    border-bottom: 2px solid #E9EBED; }
    .modal-details .modal-details-header:empty {
      margin: 0; }
    .modal-details .modal-details-header > * {
      min-width: 33.33%; }
    .modal-details .modal-details-header .header-middle {
      display: -webkit-flex;
      display: flex; }
    .modal-details .modal-details-header .header-right {
      display: -webkit-flex;
      display: flex; }
      @media (min-width: 992px) {
        .modal-details .modal-details-header .header-right > * {
          -webkit-flex-grow: 1;
                  flex-grow: 1; } }
  .modal-details .modal-details-content {
    padding: 2rem;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    overflow-y: auto; }
    .modal-details .modal-details-content > .spinner {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      justify-self: center;
      margin: auto; }
  .modal-details .modal-details-footer {
    border-top: 2px solid #E9EBED;
    padding: 2rem; }

@media (min-width: 992px) {
  .modal-details .modal-details-header h2 {
    margin: 0; }
  .modal-details .modal-details-header .flex-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start; }
    .modal-details .modal-details-header .flex-header .header-middle {
      -webkit-justify-content: center;
              justify-content: center; }
    .modal-details .modal-details-header .flex-header .header-right {
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      margin-right: 2rem; }
  .modal-details .modal-details-header .header-wide {
    width: 100%; } }

.request-log-details .heading {
  padding-bottom: .5rem;
  margin-bottom: 1rem; }

.request-log-details .subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  color: #6C7380;
  margin-bottom: 1rem; }

.service-details-main .info-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: .5rem;
  margin-bottom: 1rem;
  place-items: center; }
  .service-details-main .info-item .info-icon {
    width: 4.75rem;
    height: 4.75rem;
    display: -webkit-flex;
    display: flex;
    background: #F8F8F9;
    border-radius: .25rem;
    color: #0A162B;
    margin-right: 1.5rem; }
    .service-details-main .info-item .info-icon span {
      margin: auto; }
  .service-details-main .info-item .info-content {
    font-weight: 400; }
  .service-details-main .info-item .info-title {
    font-size: 0.875rem;
    text-transform: uppercase;
    margin-bottom: .5rem;
    color: #6C7380; }
  .service-details-main .info-item .info-data {
    font-size: 1.125rem;
    color: #3B4456; }
  .service-details-main .info-item .icon-text {
    font-size: 0.75rem;
    font-weight: 500; }

.info-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: 1rem; }
  .info-item.expandable {
    cursor: pointer; }
    .info-item.expandable .info-data {
      max-height: 5.5rem;
      overflow: hidden;
      position: absolute;
      background: #fff;
      padding: .25rem;
      border: 1px solid #F8F8F9; }
    .info-item.expandable.show .info-data {
      max-height: none;
      z-index: 5; }
    .info-item.expandable .info-title .icon-item {
      color: #5E5CE6; }
  .info-item .info-icon {
    width: 2.5rem;
    height: 2.5rem;
    display: -webkit-flex;
    display: flex;
    background: #F8F8F9;
    border-radius: 2rem;
    border: 1px solid #E9EBED;
    color: #0A162B; }
    .info-item .info-icon span {
      margin: auto; }
  .info-item .info-content {
    padding: .5rem; }
  .info-item .info-title {
    font-size: 0.625rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: .5rem;
    color: #0A162B;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    grid-column-gap: .25rem;
    -webkit-column-gap: .25rem;
            column-gap: .25rem; }
    .info-item .info-title a {
      font-weight: 400; }
  .info-item .info-data {
    font-size: 0.875rem; }
    .info-item .info-data .font-weight-bold {
      color: #0A162B; }
  .info-item .icon-text {
    font-size: 0.75rem;
    font-weight: 500; }

.health-check-details-view {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  .health-check-details-view .header-left .title {
    margin-bottom: 1rem; }
  .health-check-details-view .header-left .custom-select-advanced {
    font-size: 2rem;
    border: none;
    color: #0A162B;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0; }
    .health-check-details-view .header-left .custom-select-advanced option {
      font-size: 1rem; }
  @media (max-width: 575px) {
    .health-check-details-view .header-right button:not(:last-child) {
      margin-bottom: .5rem; } }
  @media (min-width: 576px) {
    .health-check-details-view .header-right {
      display: -webkit-flex;
      display: flex; } }
  .health-check-details-view .health-chek-details-info {
    display: -webkit-flex;
    display: flex;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
            column-gap: 1.5rem;
    font-weight: 500;
    font-size: 0.875rem; }
    .health-check-details-view .health-chek-details-info .info-item {
      margin-bottom: 0; }
    .health-check-details-view .health-chek-details-info .user-name-value {
      color: #0A162B; }
  .health-check-details-view .health-check-details-content {
    height: 66.6vh;
    overflow-y: auto; }

.error-indicator {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 20rem; }
  .error-indicator .image-wrapper {
    padding: 2rem; }
    .error-indicator .image-wrapper img {
      max-width: 100%; }
    @media (min-width: 576px) {
      .error-indicator .image-wrapper {
        padding: 4rem; } }
  .error-indicator .subtext {
    color: #6c7380; }

.topbar {
  border: 1px solid #CED0D5;
  border-radius: 0.625rem;
  padding: 1.5rem 1.5rem 0 1.5rem; }
  .topbar.no-filter {
    padding: 1.5rem; }
    .topbar.no-filter .top-bar-data {
      margin-bottom: 0; }
  .topbar .top-bar-data {
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 1.5rem; }
    @media (min-width: 1200px) {
      .topbar .top-bar-data {
        display: -webkit-flex;
        display: flex; } }
    .topbar .top-bar-data.opened .nav {
      max-height: 230px;
      opacity: 1;
      height: auto;
      -webkit-transition: all .3s ease-in;
      transition: all .3s ease-in; }
    .topbar .top-bar-data .nav {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      -webkit-transition: opacity .3s ease-in;
      transition: opacity .3s ease-in; }
      @media (min-width: 1200px) {
        .topbar .top-bar-data .nav {
          -webkit-flex-direction: row;
                  flex-direction: row;
          grid-column-gap: .5rem;
          -webkit-column-gap: .5rem;
                  column-gap: .5rem;
          opacity: 1;
          max-height: none; } }
      .topbar .top-bar-data .nav .nav-item {
        position: relative;
        text-align: left;
        margin-bottom: .5rem; }
        @media (min-width: 1200px) {
          .topbar .top-bar-data .nav .nav-item {
            margin-bottom: 0; }
            .topbar .top-bar-data .nav .nav-item .collapse, .topbar .top-bar-data .nav .nav-item .collapsing {
              display: none;
              opacity: 0;
              visibility: hidden;
              position: absolute;
              bottom: 0;
              left: -200%;
              -webkit-transition: opacity .2s ease;
              transition: opacity .2s ease; }
            .topbar .top-bar-data .nav .nav-item:hover .nav-link:not(.active) {
              background: #CED0D5; }
              .topbar .top-bar-data .nav .nav-item:hover .nav-link:not(.active)[aria-expanded=true] {
                background: #CED0D5; }
            .topbar .top-bar-data .nav .nav-item:hover .collapse, .topbar .top-bar-data .nav .nav-item:hover .collapsing {
              opacity: 1;
              visibility: visible;
              bottom: 0;
              left: 100%;
              -webkit-transition: opacity .5s ease;
              transition: opacity .5s ease; } }
      .topbar .top-bar-data .nav .nav-link {
        display: -webkit-inline-flex;
        display: inline-flex;
        padding: .5rem 1rem;
        font-size: 1.375rem;
        line-height: 1;
        -webkit-align-items: center;
                align-items: center;
        color: #0A162B;
        font-weight: 400;
        text-transform: capitalize;
        border-radius: 40px;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out; }
        .topbar .top-bar-data .nav .nav-link .icon-item {
          margin-right: .5rem;
          margin-bottom: 0; }
          @media (min-width: 1200px) {
            .topbar .top-bar-data .nav .nav-link .icon-item {
              margin-right: 0;
              margin-bottom: .5rem; } }
          .topbar .top-bar-data .nav .nav-link .icon-item.boxed {
            background: #3B4456;
            border: none;
            display: -webkit-flex;
            display: flex;
            padding: 0.3rem; }
        .topbar .top-bar-data .nav .nav-link .expand-icon {
          position: absolute;
          right: 0; }
          @media (min-width: 1200px) {
            .topbar .top-bar-data .nav .nav-link .expand-icon {
              top: 1rem;
              margin: 0; } }
        .topbar .top-bar-data .nav .nav-link.parent {
          position: relative; }
          .topbar .top-bar-data .nav .nav-link.parent + .collapse .nav-link, .topbar .top-bar-data .nav .nav-link.parent + .collapsing .nav-link {
            padding-left: 3.4rem;
            background: transparent;
            text-transform: none;
            font-size: 1rem;
            font-weight: 400; }
          @media (min-width: 1200px) {
            .topbar .top-bar-data .nav .nav-link.parent + .collapse, .topbar .top-bar-data .nav .nav-link.parent + .collapsing {
              background: #CED0D5;
              width: 15rem; }
              .topbar .top-bar-data .nav .nav-link.parent + .collapse .nav-link, .topbar .top-bar-data .nav .nav-link.parent + .collapsing .nav-link {
                display: block;
                padding: 1rem; }
                .topbar .top-bar-data .nav .nav-link.parent + .collapse .nav-link:hover, .topbar .top-bar-data .nav .nav-link.parent + .collapsing .nav-link:hover {
                  color: #9DA2AA; } }
        .topbar .top-bar-data .nav .nav-link[aria-expanded=true] {
          background: #CED0D5;
          position: relative; }
          .topbar .top-bar-data .nav .nav-link[aria-expanded=true] .icon-item.boxed .material-icons {
            color: #fff; }
          .topbar .top-bar-data .nav .nav-link[aria-expanded=true] + .collapse .nav .nav-link.active, .topbar .top-bar-data .nav .nav-link[aria-expanded=true] .collapsing .nav .nav-link.active {
            color: #5E5CE6; }
            @media (min-width: 1200px) {
              .topbar .top-bar-data .nav .nav-link[aria-expanded=true] + .collapse .nav .nav-link.active, .topbar .top-bar-data .nav .nav-link[aria-expanded=true] .collapsing .nav .nav-link.active {
                color: #0A162B; }
                .topbar .top-bar-data .nav .nav-link[aria-expanded=true] + .collapse .nav .nav-link.active:hover, .topbar .top-bar-data .nav .nav-link[aria-expanded=true] .collapsing .nav .nav-link.active:hover {
                  color: #9DA2AA; } }
        @media (min-width: 1200px) {
          .topbar .top-bar-data .nav .nav-link[aria-expanded=true] {
            background: #0A162B; }
            .topbar .top-bar-data .nav .nav-link[aria-expanded=true]:hover {
              background: #CED0D5; } }
        .topbar .top-bar-data .nav .nav-link.collapsed .expand-icon {
          -webkit-transform: translateY(-50%);
                  transform: translateY(-50%); }
        .topbar .top-bar-data .nav .nav-link.active {
          background: #0A162B;
          color: #fff; }
          .topbar .top-bar-data .nav .nav-link.active .icon-item.boxed {
            background: #fff; }
            .topbar .top-bar-data .nav .nav-link.active .icon-item.boxed .material-icons {
              color: #5E5CE6; }
        @media (min-width: 1200px) {
          .topbar .top-bar-data .nav .nav-link {
            padding: .25rem 1rem;
            position: relative;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
                    flex-direction: column;
            -webkit-justify-content: center;
                    justify-content: center;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out; } }
    .topbar .top-bar-data .logo-wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-bottom: 1rem; }
      .topbar .top-bar-data .logo-wrapper .logo {
        width: auto; }
      @media (min-width: 1200px) {
        .topbar .top-bar-data .logo-wrapper {
          margin-right: 1rem;
          margin-bottom: 0; } }
    .topbar .top-bar-data .info-wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-align-content: center;
              align-content: center;
      -webkit-justify-content: space-between;
              justify-content: space-between; }
      @media (min-width: 1200px) {
        .topbar .top-bar-data .info-wrapper {
          margin-left: auto;
          -webkit-flex-direction: column-reverse;
                  flex-direction: column-reverse; } }
    .topbar .top-bar-data .date-info {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin-right: 1rem; }
      .topbar .top-bar-data .date-info .day-details {
        margin-bottom: .25rem; }
      @media (min-width: 576px) {
        .topbar .top-bar-data .date-info {
          -webkit-flex-direction: row;
                  flex-direction: row; }
          .topbar .top-bar-data .date-info .day-details {
            margin-right: .5rem; } }
      @media (min-width: 1200px) {
        .topbar .top-bar-data .date-info {
          margin-left: auto; } }
    .topbar .top-bar-data .user-info {
      color: #3B4456;
      margin: 0 0 .5rem; }
      @media (min-width: 1200px) {
        .topbar .top-bar-data .user-info {
          margin: 0; } }

.sidebar-toggle {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 1.5rem;
  width: 1.75rem;
  position: relative;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out; }
  .sidebar-toggle.opened {
    -webkit-transform: rotate(540deg);
            transform: rotate(540deg); }
    .sidebar-toggle.opened span:nth-child(3) {
      width: 50%; }
    .sidebar-toggle.opened span:nth-child(2) {
      width: 75%; }
  .sidebar-toggle span {
    display: block;
    height: .25rem;
    width: 100%;
    background: #0A162B;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out; }

.logo {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  width: 100%; }
  .logo a {
    display: -webkit-flex;
    display: flex; }

@media (min-width: 1200px) {
  .logo {
    -webkit-justify-content: flex-start;
            justify-content: flex-start; }
    .logo a {
      width: auto; } }

.user-info {
  color: #fff;
  display: -webkit-flex;
  display: flex;
  margin: 0 1.25rem;
  -webkit-align-items: center;
          align-items: center; }
  .user-info .user-name {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: 400;
    margin-right: .5rem;
    max-width: 10rem;
    word-break: break-word; }
    .user-info .user-name a {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
    .user-info .user-name .icon-item {
      margin-right: .625rem; }
    .user-info .user-name .icon-letter {
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      font-size: 1.375rem;
      color: #fff;
      background: #FA8C16;
      text-transform: uppercase;
      border-radius: 50%;
      width: 2.125rem;
      height: 2.125rem;
      margin-right: .5rem; }
  .user-info .btn-link {
    color: inherit;
    padding-left: 0; }

.dialog-bgr {
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: .5; }

.dialog .dialog-header h4 {
  margin-bottom: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .dialog .dialog-header h4 .icon-item {
    margin-right: .5rem; }

.dialog .dialog-text {
  margin-bottom: 2rem; }

.dialog .button-wrapper .btn:last-child {
  margin-left: .5rem; }

.page {
  padding-top: 1.5rem; }
  .page .topbar, .page .page-content-wrapper {
    width: calc(100% - 2rem);
    margin: 0 auto; }
  .page .topbar {
    margin-bottom: 2rem; }
  @media (min-width: 1200px) {
    .page .topbar {
      width: calc(100% - 4rem);
      margin-bottom: 3rem; }
    .page .page-content-wrapper {
      width: calc(100% - 18.75rem); } }

.to-top-button .scroll-container {
  position: fixed;
  right: -9rem;
  bottom: 2rem;
  -webkit-transition: right 0.5s;
  transition: right 0.5s;
  cursor: pointer; }

.to-top-button .scroll-transition {
  right: 1.5rem; }

.to-top-button .icon-wrapper {
  display: -webkit-flex;
  display: flex; }

.to-top-button .btn {
  background: white;
  border-radius: 2rem;
  padding: .8125rem;
  border-color: #CED0D5;
  color: #0A162B; }
  .to-top-button .btn:hover {
    border-color: #6C7380;
    background: white;
    color: #0A162B; }

.users-page .to-top-button .scroll-container {
  bottom: 2rem; }

.radio-button-group .btn {
  text-transform: none;
  text-transform: initial;
  font-weight: 400;
  padding: 0.875rem 1.9375rem;
  line-height: 1;
  font-size: 1rem;
  height: 3rem; }
  .radio-button-group .btn.btn-outline-primary {
    color: #3B4456; }
    .radio-button-group .btn.btn-outline-primary:hover {
      box-shadow: none;
      background: #E9EBED; }

.health-check-statuses-list {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr; }

.health-check-list-item {
  font-size: .875rem; }
  .health-check-list-item.card.card-item {
    margin-bottom: 0; }
  .health-check-list-item .card-header {
    cursor: pointer; }
    .health-check-list-item .card-header:hover .title {
      color: #5E5CE6; }
  .health-check-list-item .grid-cell {
    padding: .5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
    .health-check-list-item .grid-cell.health-rate {
      font-weight: 500; }
  .health-check-list-item .heading {
    display: block;
    margin-bottom: 0; }
    .health-check-list-item .heading .title {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      grid-column-gap: .5rem;
      -webkit-column-gap: .5rem;
              column-gap: .5rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1;
      margin-bottom: .5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .health-check-list-item .heading .nav-link {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-align-content: center;
              align-content: center;
      line-height: 1; }
      .health-check-list-item .heading .nav-link .icon-item {
        margin-right: .5rem; }
  .health-check-list-item .statuses-heading {
    display: grid;
    grid-template-columns: 4rem 4rem 1fr 1fr;
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: 400; }
    .health-check-list-item .statuses-heading > * {
      padding: .5rem;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .health-check-list-item .statuses-heading > *:last-child {
        text-align: right; }
  .health-check-list-item .statuses-content {
    display: grid;
    grid-template-columns: 4rem 4rem 1fr 1fr; }
    .health-check-list-item .statuses-content .health-response-time {
      text-align: right; }

@media (min-width: 576px) {
  .health-check-statuses-list {
    grid-template-columns: repeat(auto-fill, minmax(365px, 1fr)); }
  .health-check-list-item {
    font-size: .875rem; }
    .health-check-list-item .heading {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-align-content: center;
              align-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .health-check-list-item .heading .title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0; }
      .health-check-list-item .heading .nav-link {
        padding: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-align-content: center;
                align-content: center;
        line-height: 1; }
        .health-check-list-item .heading .nav-link .icon-item {
          margin-right: .25rem; }
    .health-check-list-item .statuses-heading {
      font-size: .75rem;
      text-transform: uppercase;
      font-weight: 500; } }

.healthCheck-panel .custom-input-box input {
  width: 19rem; }

.health-check-details table tr th:nth-child(1) {
  width: 6rem; }

.health-check-details table tr th:nth-child(2) {
  width: 12rem; }

.health-check-details table tr th:nth-child(3) {
  width: 7rem; }

.page-name {
  width: calc(100% - 9rem); }
  @media (min-width: 1200px) {
    .page-name {
      width: 50%; } }

.breadcrumbs {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-size: 0.75rem; }

.not-found-page {
  display: grid;
  -webkit-flex-direction: column;
          flex-direction: column;
  place-items: center;
  place-content: center;
  text-align: center;
  height: 80vh; }
  .not-found-page .image-wrapper,
  .not-found-page .info {
    margin-bottom: 2rem; }

.barcodes-panel .panel-heading .radio-button-group .btn:first-child .icon-wrapper {
  -webkit-transform: scaleX(-1) rotate(180deg);
          transform: scaleX(-1) rotate(180deg); }

.barcodes-panel .panel-heading .download-button-wrap {
  margin-left: auto; }

.barcodes-panel .mobile-heading-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .barcodes-panel .mobile-heading-content .btn {
    margin-bottom: 1rem; }

.barcodes-panel .barcodes-search {
  width: 18rem; }

.barcodes-view .barcodes-table-view.table {
  table-layout: fixed; }
  .barcodes-view .barcodes-table-view.table thead th {
    background: #F8F8F9;
    color: #0A162B;
    padding: 1rem; }
    .barcodes-view .barcodes-table-view.table thead th:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
      width: 3rem; }
    .barcodes-view .barcodes-table-view.table thead th:last-child {
      border-radius: 0 0.5rem 0.5rem 0; }
    .barcodes-view .barcodes-table-view.table thead th.header-user, .barcodes-view .barcodes-table-view.table thead th.header-carrier {
      width: 9rem; }
    .barcodes-view .barcodes-table-view.table thead th.header-prefix {
      width: 6rem; }
    .barcodes-view .barcodes-table-view.table thead th.header-begin, .barcodes-view .barcodes-table-view.table thead th.header-end {
      width: 12rem; }
    .barcodes-view .barcodes-table-view.table thead th.header-remaining {
      width: 12rem; }
    .barcodes-view .barcodes-table-view.table thead th.actions {
      width: 6rem; }
  .barcodes-view .barcodes-table-view.table .ranges-details-list {
    display: none;
    background: #F8F8F9; }
    .barcodes-view .barcodes-table-view.table .ranges-details-list.is-open {
      visibility: visible;
      display: table-row; }
  .barcodes-view .barcodes-table-view.table .ranges-common-data td:hover {
    cursor: pointer; }
    .barcodes-view .barcodes-table-view.table .ranges-common-data td:hover span {
      cursor: auto; }
  .barcodes-view .barcodes-table-view.table .ranges-common-data td .btn-link {
    color: #0A162B; }
  .barcodes-view .barcodes-table-view.table .ranges-common-data.is-open {
    background: #E9EBED; }

.barcodes-view .barcodes-card-view {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 1.5rem; }
  .barcodes-view .barcodes-card-view .card-item {
    margin-bottom: 0; }
  .barcodes-view .barcodes-card-view .card-body .common-data {
    margin-bottom: 1rem; }
  .barcodes-view .barcodes-card-view .card-body .details-data {
    display: none; }
    .barcodes-view .barcodes-card-view .card-body .details-data.is-open {
      display: block; }
    .barcodes-view .barcodes-card-view .card-body .details-data .ranges-details-list {
      padding: .5rem;
      margin-left: -.5rem;
      margin-right: -.5rem;
      margin-bottom: 1rem;
      background: #F8F8F9;
      border-radius: .25rem; }
  .barcodes-view .barcodes-card-view .card-item-section {
    display: -webkit-flex;
    display: flex;
    margin-bottom: .75rem;
    line-height: .875rem; }
    .barcodes-view .barcodes-card-view .card-item-section .card-item-label {
      font-size: 0.625rem;
      font-weight: 400;
      text-transform: uppercase;
      width: 6rem;
      margin-right: 1.5rem; }
    .barcodes-view .barcodes-card-view .card-item-section .card-item-data {
      font-size: 0.875rem;
      width: calc(100% - 7.5rem); }
  .barcodes-view .barcodes-card-view .delete-barcode.custom-button.btn.icon-left.btn-sm {
    padding-left: 0; }
  @media (min-width: 576px) {
    .barcodes-view .barcodes-card-view {
      display: block; }
      .barcodes-view .barcodes-card-view .card-item {
        margin-bottom: 1.5rem; }
      .barcodes-view .barcodes-card-view .card-body .common-data {
        display: -webkit-flex;
        display: flex; }
        .barcodes-view .barcodes-card-view .card-body .common-data .card-item-section {
          display: block;
          margin-right: 2rem; }
          .barcodes-view .barcodes-card-view .card-body .common-data .card-item-section .card-item-label {
            margin-bottom: .5rem;
            margin-right: auto;
            width: auto; }
          .barcodes-view .barcodes-card-view .card-body .common-data .card-item-section .card-item-data {
            width: auto; }
      .barcodes-view .barcodes-card-view .card-body .details-data {
        display: none; }
        .barcodes-view .barcodes-card-view .card-body .details-data.is-open {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
          grid-gap: 1.5rem; } }

.barcodes-view .delete-barcode:not([disabled]) {
  color: #F44336; }

.credentials-panel .mobile-heading-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.credentials-panel .credentials-search {
  width: 18rem; }

.credentials-view .credentials-table-view.table {
  table-layout: fixed; }
  .credentials-view .credentials-table-view.table thead th {
    background: #F8F8F9;
    color: #0A162B;
    padding: 1rem; }
    .credentials-view .credentials-table-view.table thead th:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
      width: 3rem; }
    .credentials-view .credentials-table-view.table thead th:last-child {
      border-radius: 0 0.5rem 0.5rem 0;
      width: 5rem; }
    .credentials-view .credentials-table-view.table thead th.header-carrier {
      width: 8rem; }
    .credentials-view .credentials-table-view.table thead th.header-id {
      width: 6rem; }
    .credentials-view .credentials-table-view.table thead th.header-name {
      width: 14rem; }
  .credentials-view .credentials-table-view.table .services-list {
    display: none;
    background: #F8F8F9; }
    .credentials-view .credentials-table-view.table .services-list.is-open {
      visibility: visible;
      display: table-row; }

.credentials-group {
  padding: 10px 20px;
  margin-bottom: 5px;
  border-bottom: 1px solid #CED0D5; }
  .credentials-group.is-open {
    border: 2px dashed #5E5CE6;
    border-radius: 5px; }

.cursor-pointer {
  cursor: pointer; }

.fixed-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  bottom: 20px; }

.carriers-search {
  width: 18rem; }

.tracking-map-table-view.table {
  table-layout: fixed; }
  .tracking-map-table-view.table thead th {
    background: #F8F8F9;
    color: #0A162B;
    padding: 1rem; }
    .tracking-map-table-view.table thead th:first-child {
      border-radius: 0.5rem 0 0 0.5rem; }
    .tracking-map-table-view.table thead th:last-child {
      border-radius: 0 0.5rem 0.5rem 0; }
  .tracking-map-table-view.table .edit-statuses {
    width: 3rem; }

.tracking-map-table-view.table {
  table-layout: fixed; }
  .tracking-map-table-view.table thead th {
    background: #F8F8F9;
    color: #0A162B;
    padding: 1rem; }
    .tracking-map-table-view.table thead th:first-child {
      border-radius: 0.5rem 0 0 0.5rem; }
    .tracking-map-table-view.table thead th:last-child {
      border-radius: 0 0.5rem 0.5rem 0; }
  .tracking-map-table-view.table .edit-statuses {
    width: 3rem; }

.statuses-search {
  width: 18rem; }

.actions {
  width: 7rem; }

.delete-status {
  color: #F44336; }

.width-500 {
  width: 500px; }

.login-button {
  margin: 0 auto;
  width: 75%; }

.error-info {
  text-align: center;
  height: 2rem; }

.login-page {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex; }
  .login-page .container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  @media (min-width: 576px) {
    .login-page .content-wrapper {
      max-width: 18.75rem; } }

.auth-layout {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh; }
  .auth-layout .container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: 100%; }
  .auth-layout .content-wrapper {
    margin: auto;
    width: 100%; }
    .auth-layout .content-wrapper .logo-wrapper {
      text-align: center; }
    @media (min-width: 576px) {
      .auth-layout .content-wrapper {
        max-width: 18.75rem; } }
  @media (min-width: 576px) {
    .auth-layout {
      height: 100vh; } }

.App {
  min-height: 100vh; }
  .App > .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    margin: auto; }

.alert {
  box-shadow: 0 1px 4px rgba(10, 22, 43, 0.25);
  border: none; }
  .alert.alert-danger {
    background: #FFEFEF;
    color: #F44336; }
  .alert.alert-success {
    background: #D9F3EB;
    color: #008F86; }
  .alert.alert-primary {
    background: #C5D1FB;
    color: #5E5CE6; }
  .alert .icon-item {
    margin-left: .5rem;
    color: #0A162B; }
  .alert.undo-toast .icon-item {
    display: none; }
  .alert .content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .alert .content .material-icons {
      margin-right: .5rem;
      -webkit-align-self: center;
              align-self: center; }
    .alert .content .btn {
      margin-left: auto; }

