@import "../../base_styles/custom_variables";

.error-indicator {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  .image-wrapper {
    padding: 2rem;
    img {
      max-width: 100%;
    }
    @media (min-width: 576px) {
      padding: 4rem;
    }
  }
  .subtext {
    color: $subtitle-text-color;
  }


}