@import "../../../base_styles/custom_variables_2_0";

.modal-details {
  height: 90vh;
  display: flex;
  flex-direction: column;

  .modal-details-header {
    //margin-bottom: .5rem;
    padding: 2rem;
    border-bottom: 2px solid $dark-5;
    &:empty {
      margin: 0;
    }

    & > * {
      //margin-bottom: .5rem;
      min-width: 33.33%;
    }

    .header-middle {
      display: flex;
    }

    .header-right {
      display: flex;
      @media(min-width: 992px) {
        & > * {
          flex-grow: 1;
        }
      }
    }
  }

  .modal-details-content {
    padding: 2rem;
    flex-grow: 1;
    overflow-y: auto;
    //>*:first-child {
    //  margin-top: .5rem;
    //}
    >.spinner {
      flex-grow: 1;
      justify-self: center;
      margin: auto;
    }
  }

  .modal-details-footer {
    border-top: 2px solid $dark-5;
    padding: 2rem;
  }


}


@media (min-width: 992px) {
  .modal-details {
    .modal-details-header {
      h2 {
        margin: 0;
      }
      .flex-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .header-middle {
          justify-content: center;
        }

        .header-right {
          justify-content: flex-end;
          margin-right: 2rem;
        }
      }
      .header-wide {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1200px) {
}
