@import "../../../base_styles/custom_variables_2_0";

.page-data-panel {
  margin-bottom: 1rem;
   .panel {
    .panel-heading {
      margin-bottom: 1.5rem;
      .full-heading-content {
        display: flex;
        & > * {
          margin-right: 1.5rem;
          &:last-child {
            margin-right: 0;
            margin-left: auto;
          }
        }
        .mobile-switcher {
          @media (min-width: 1200px) {
            display:none;
          }
        }
      }
      .mobile-heading-content {
        padding-top: $v-space-base*2;
        max-height: 15rem;
        visibility: visible;
        opacity: 1;
        transition: all .3s ease;

        &.hidden-item {
          //display:none;
          max-height: 0;
          overflow: hidden;
          visibility: hidden;
          opacity: 0;
          transition: all .3s ease;
        }
        @media (min-width: 1200px) {
          display:none;
        }
      }

    }
  }
  &.widget {
    &.card {
      padding: 1rem;
    }
    .panel {
      .panel-heading {
        margin-bottom: 0;
        border:none;
      }
    }
  }
}




@media (min-width: 1200px) {}
