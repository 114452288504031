@import "./../../../base_styles/custom_variables_2_0";
.to-top-button {
  .scroll-container {
    position: fixed;
    right: -9rem;
    bottom: 2rem;
    transition: right 0.5s;
    cursor: pointer;
  }

  .scroll-transition {
    right: 1.5rem;
  }

  .icon-wrapper {
    display: flex;
  }

  .btn {
    background: white;
    border-radius: $button-border-radius;
    padding: .8125rem;
    border-color: $dark-6;
    color: $dark-10;
    &:hover {
      border-color: $dark-8;
      background: white;
      color: $dark-10;
    }
  }
}


.users-page {
  .to-top-button {
    .scroll-container {
      bottom: 2rem;
    }
  }
}
