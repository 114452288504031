@import "../../../base_styles/custom_variables_2_0";

.table-card {
  table {
    table-layout: fixed;

    tr {
      th {
        background: $dark-3;
        color: $text-color-title;
        padding: 1rem;

        &.actions {
          width: 6rem;
        }

        &:first-child {
          border-radius: $input-border-radius 0 0 $input-border-radius;
        }

        &:last-child {
          border-radius: 0 $input-border-radius $input-border-radius 0;
        }
      }

      td {
        &.actions {
          text-align: right;

          .nav-link {
            margin-left: auto;
          }
        }
      }
    }

  }

  &.card-view {
    .card-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      grid-gap: 1.5rem;
      margin-bottom: 2rem;

      .card-item {
        height: 100%;

        .card-body {
          height: 100%;
        }
      }
    }

    .card-item-section {
      display: flex;
      margin-bottom: .75rem;
      line-height: $font-size-14;

      .badge {
        margin-bottom: .25rem;
        max-width: 100%;
        white-space: normal;
        text-align: left;
        //overflow: hidden;
        //text-overflow: ellipsis;
      }

      > div {
        &:first-child {
          font-size: $font-size-10;
          font-weight: $font-weight-normal;
          text-transform: uppercase;
          width: 6rem;
          margin-right: 1.5rem;
        }

        &:last-child {
          font-size: $font-size-14;
          width: calc(100% - 7.5rem);
        }

        .buttons-wrapper {
          display: flex;

          .btn + .btn {
            margin-left: .5rem;
          }
        }
      }
    }

    .card-item-label {
      color: $text-color-title;
    }
  }

  &.request-log {
    table {
      tr {
        td {
          &:first-child {
            font-weight: $font-weight-bold;
          }
        }
      }

    }
  }
}


