.request-log-panel {
  table {
    tr {
      th {
        &:nth-child(1) {
          width: 3rem;
        }
        &:nth-child(2) {
          width: 9rem;
        }
        &:nth-child(3) {
          width: 7rem;
        }
        &:nth-child(4) {
          width: 7rem;
        }
        &:nth-child(5), {
          width: 9rem;
        }
        &:nth-child(6) {
          width: 4rem;
        }
        &:nth-child(7) {
          width: 10rem;
        }
        &:nth-child(8) {
          width: 5rem;
        }
        &:nth-child(9) {
          width: 5rem;
        }
      }
    }
  }
  .spinner {
    margin: auto;
  }
}
  @media (min-width: 1200px) {
    .request-log-panel {
      table {
        &.table-flex {
          table-layout: auto;
          .link-icon {
            .nav-link {
              margin-left: auto;
            }
          }
        }
      }
    }
  }

