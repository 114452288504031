@import "../../../base_styles/custom_variables_2_0";

.radio-button-group {
  .btn {
    text-transform: initial;
    font-weight: $font-weight-normal;
    padding: $baseRem*14 $baseRem*31;
    line-height: 1;
    font-size: 1rem;
    height: 3rem;
    &.btn-outline-primary {
      color: $dark-9;
      &:hover {
        box-shadow: none;
        background: $secondary;
      }
    }

  }
}

