@import "../../base_styles/custom_variables";

.spinner {
  width: 35px;
  height: 35px;
  position: relative;
  margin: auto;
}

.lds-dual-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  z-index: 10;
  &:after {
    content: " ";
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 5px solid;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  &.light:after {
    border-color: $white transparent $white transparent;
  }
  &.dark {
    &:after {
      border-color: $primary transparent $primary transparent;
    }
  }

}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
