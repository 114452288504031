@import "../../../base_styles/custom_variables_2_0";

.filter-layout {
  margin-bottom: $v-space-base * 8;
  .filter-form {
    max-height: 1000px;
    opacity: 1;
    transition: all .3s ease;
  }
  .footer-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .filter-toggler {
    @media (min-width: 1200px) {
      display: none;
    }
    margin-left: auto;
    margin-bottom: 2rem;
    transition: all .3s ease;
    }
  &.hidden-filter {
    margin-bottom: 0;
    .filter-form {
      max-height: 0;
      opacity: 0;
      transition: all .3s ease;
      //overflow: hidden;
      @media (min-width: 1200px) {
        max-height: 1000px;
        opacity: 1;
        //overflow: visible;
      }
    }
    .filter-toggler {
      margin-left: auto;
      margin-bottom: 0;
      transition: all .3s ease;
    }
    @media (min-width: 1200px) {
      //margin-bottom: $v-space-base * 8;
    }
  }
}
