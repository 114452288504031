@import "../../../base_styles/custom_variables_2_0";

.dropdown-checkbox-list {
  .dropdown {
    .form-control{
      cursor:pointer;
      height: 3rem;
      padding-right: 2rem;
      &:disabled,
      &.form-control[readonly],
      &:disabled:hover,
      &.form-control[readonly]:hover{
        border-color: $dark-5;
      }
    }
    & >.icon-item {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0.5rem;
      color: $dark-10;
      cursor: pointer;
    }
    .dropdown-menu {
      max-height: 21rem;
      overflow-y: auto;
    }
  }
}
