@import "../../base_styles/custom_variables_2_0";

.credentials-panel {
  .mobile-heading-content {
    display: flex;
    flex-wrap: wrap;
  }
  .credentials-search {
    width: 18rem;
  }

}
