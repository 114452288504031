@import "../../base_styles/custom_variables_2_0";

.barcodes-panel {
  .panel-heading {
    .radio-button-group {
      .btn {
        &:first-child {
          .icon-wrapper {
            transform: scaleX(-1) rotate(180deg);
          }
        }
      }
    }
    .download-button-wrap {
      margin-left: auto;
    }
  }
  .mobile-heading-content {
    display: flex;
    flex-wrap: wrap;

    .btn {
      margin-bottom: 1rem;
    }
  }
  .barcodes-search {
    width: 18rem;
  }

}
