@import "../../../base_styles/custom_variables_2_0";

.custom-input-box {
  &:hover:not(.focused){
    &.with-icon {
      .icon-item {
        color: $text-color-helper;
      }
    }
  }
  .icon-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &.with-icon {
    .icon-item {
      color: $text-color-disabled;
    }
  }
  &.icon-left {
    .form-control {
      padding-left: 2rem;
      &.input-sm {
        padding-left: 2rem;
      }
    }
    .icon-wrapper {
      left: .5rem;
    }
  }
  &.icon-right {
    .form-control {
      padding-right: 2rem;
      &.input-sm {
        padding-right: 2rem;
      }
    }
    .icon-wrapper {
      right: .5rem;
    }
  }
  &.disabled {
    label {
      color:$placeholder-and-passive-text-color;
    }
  }
  &.focused {
    .icon-item {
      color: $primary;
    }
  }
  .form-control {
    &.input-sm {
      font-size: $baseRem*14;
    }
  }
  .input-wrapper + .custom-progress {
    margin-top: .25rem;
  }
  .sub-message {
    position: absolute;
    top: 105%;
    left: 1rem
  }

}
