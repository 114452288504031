@import "../../../base_styles/custom_variables_2_0";

.date-time-picker {
  .inputs-wrapper {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: start;
    grid-column-gap: .5rem;
    &.date-from {
      .custom-input-box {
        &:first-of-type {
          input {
            border-color: $input-active-border-color;
          }
        }
      }
    }
    &.date-to {
      .custom-input-box {
        &:last-of-type {
          input {
            border-color: $input-active-border-color;
          }
        }
      }
      & + .calendar-panel {
        right: 0;
      }
    }
    &.date-range {
      .custom-input-box {
        input {
          border-color: $input-active-border-color;
        }
      }
    }
    .custom-input-box {
      &:last-child {
        grid-column: 1/3;
      }

      &.disabled {
        label {
          color: inherit;
        }
      }
    }
    .sub-message {
      position: absolute;
      bottom: .5rem;
      left: 4.5rem;
    }
    @media (min-width: 576px) {
      grid-template-columns: 6.5rem 1fr 1fr;
      .custom-input-box {
        &:last-child {
          grid-column: 3/4;
        }
      }
    }
  }

  .date-time-pick-calendar {
     height: 20rem;
    .calendar-navigation {
      display: grid;
      grid-template-columns: 2rem 1fr 2rem;
      margin-bottom: 1rem;

      .month-year {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: $font-weight-bold;

        .month {
          margin-right: 1rem;
        }
      }
    }

    .week-days,
    .days-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      text-align: center;
      grid-row-gap: .5rem;
    }

    .week-days {
      font-size: $font-size-10;
      text-transform: uppercase;
      margin-bottom: .75rem;
    }

    .days-grid {
      font-size: $font-size-14;
      margin-bottom: .5rem;
    }

    .day {
      padding: .5rem .75rem;
      border-radius: $input-border-radius;
      border: 1px solid transparent;

      &:not(.other-month):hover {
        cursor: pointer;
        background: $secondary-light;
      }

      &:not(.selected):hover {
        background: $secondary-light;
      }

      &.other-month {
        color: $text-color-disabled;
        &:hover {
          cursor: default;
          background-color: initial;
        }
      }

      &.today {
        font-weight: $font-weight-bold;
        border-color: $primary;
      }
      &.forbidden {
        text-decoration: line-through;
        color: $text-color-disabled;
        &:hover {
         cursor: default;
          background-color: initial;
        }
      }
      &.in-range {
        background: $secondary-light;
        border-radius: 0;
      }
      &.selected {
        color: $white;
        background: $primary;
        border-radius: $input-border-radius;
        position: relative;

        &.same-date:before {
          content: ' ';
          position: absolute;
          border: 1px solid $white;
          border-radius: $input-border-radius;
          width: 95%;
          height: 95%;
          margin-left: 1px;
          margin-top: 1px;
          top: 0;
          left: 0;
        }
        &:hover {
          color: $white;
          background: $primary;
        }
      }

    }

    .icon-item {
      display: flex;
    }

    .next, .prev {
      padding: .25rem;
      user-select: none;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .calendar-panel {
    position: absolute;
    z-index: 10;
    padding: 1rem;
    top: 4rem;
    box-shadow: 0 20px 30px rgba(10, 22, 43, 0.08);
    .calendar-ranges {
      font-size: $font-size-14;
      color: $black;

      .range-item {
        margin-bottom: 1rem;
        cursor: pointer;
        color: $primary;
        &:hover {
          color: $primary-dark;
        }
      }
    }

    .calendar-panel-content {
      margin-bottom: 1rem;
      &:not(.single){
        .calendar-prev-month {
          .next-month-nav {
            visibility: hidden;
          }
        }
        .calendar-curr-month {
          .prev-month-nav {
            visibility: hidden;
          }
        }
      }
    }

    .calendar-panel-footer {
      border-top: 1px solid $secondary;
      padding-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-left: -1rem;
      margin-right: -1rem;

      .button-wrapper {
        display: flex;

        .btn + .btn {
          margin-left: 1rem;
        }
      }
    }

    @media (min-width: 768px) {
      .calendar-panel-content {
        &:not(.single) {
          display: grid;
          grid-template-columns: 5rem 1fr 1fr;
          grid-gap: 1rem;
        }
      }
    }
  }

  .hidden-input {
    display: none;
  }

}

