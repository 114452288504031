@import "../base_styles/custom_variables_2_0";

.login-page {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  display: flex;
  .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

  .content-wrapper {
    @media (min-width: 576px) {
      max-width: 18.75rem;
    }
  }
  }

