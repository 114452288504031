@import "../../../base_styles/custom_variables_2_0";

.auth-layout {
  padding-top: 2rem;
  padding-bottom: 2rem;
  min-height: 100vh;
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .content-wrapper {
    margin: auto;
    width: 100%;
    .logo-wrapper {
      text-align: center;
    }
    @media (min-width: 576px) {
      max-width: 18.75rem;
    }
  }
  @media (min-width: 576px)  {
    height: 100vh;
  }
}


