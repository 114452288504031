@mixin button-var($background, $color, $background-second, $color-second: $color) {
  background: $background;
  color: $color;
  border-color: $background;
  @include hover {
    background: $background;
    color: $color;
    box-shadow: 0 4px 10px rgba($background, 0.4);
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background: $gray-100;
    color:$gray-300;
    border-color: $gray-100;
    @include hover {
      background: $gray-100;
      color:$gray-300;
      border-color: $gray-100;
      box-shadow:  none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background: $background-second;
    border-color: $background-second;
    box-shadow: none;

    &:focus {
      background: $background-second;
      border-color: $background-second;
      color: $color-second;
      box-shadow: none;
    }
  }
  }

@mixin button-outline-var($color, $color-second){
  background: transparent;
  color: $color;
  border-color: $color;
  @include hover {
    background: $color;
    color: $color-second;
    box-shadow: 0 4px 10px rgba($color, 0.4);
  }
  &:focus,
  &.focus {
    outline: 0;
    box-shadow: none;
  }

  // Disabled comes first so active can properly restyle
  &.disabled,
  &:disabled {
    background: transparent;
    color:$gray-300;
    border-color: $gray-100;
    @include hover {
      background: transparent;
      color:$gray-300;
      border-color: $gray-100;
      box-shadow:  none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    background: $color;
    border-color: $color;
    color: $color-second;
    box-shadow: none;

    &:focus {
      background: $color;
      border-color: $color;
      color: $color-second;
      box-shadow: none;
    }
  }

};
