
.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  a {
    display: flex;
  }
}


// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .logo {
    justify-content: flex-start;
    a {
      width: auto;
    }
  }
}
