@import "../../base_styles/custom_variables_2_0";
.page-name {
  width: calc(100% - 9rem);
  @media (min-width: 1200px) {
    width: 50%
  }
}
.breadcrumbs {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-weight: $font-weight-normal;
  font-size: $font-size-12;
}
