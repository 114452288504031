@import "../../../../base_styles/custom_variables_2_0";

//$wellBackground: $success-light;
//$wellColor: $success;
//$issueBackground: $warning-light;
//$issueColor: $warning;
//$criticalBackground: $danger-light;
//$criticalColor: $danger;

.health-check-statuses-list {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;
}

.health-check-list-item {
  font-size: .875rem;
  &.card.card-item {
    margin-bottom: 0;
  }
  .card-header {
    cursor: pointer;
    &:hover{
      .title {
        color: $primary;
      }
    }
  }
  .grid-cell {
    padding: .5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.health-rate {
      font-weight: $font-weight-bold;
    }
}
  .heading {
    display: block;
    margin-bottom: 0;

    .title {
      display: flex;
      align-items: center;
      column-gap: .5rem;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1;
      margin-bottom: .5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .nav-link {
      display: flex;
      align-items: center;
      align-content: center;
      line-height: 1;
      .icon-item {
        margin-right: .5rem;
      }
    }
  }
  .statuses-heading {
    display: grid;
    grid-template-columns: 4rem 4rem 1fr 1fr;
    font-size: .75rem;
    text-transform: uppercase;
    font-weight: $font-weight-normal;
    > * {
      padding: .5rem;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:last-child {
        text-align: right;
      }
    }
  }

  .statuses-content {
    display: grid;
    grid-template-columns: 4rem 4rem 1fr 1fr;
    .health-response-time {
        text-align: right;
    }
  }
}


@media (min-width: 576px) {
  .health-check-statuses-list {
    grid-template-columns: repeat(auto-fill, minmax(365px, 1fr));
  }
  .health-check-list-item {
    font-size: .875rem;
    .heading {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      .title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0;
      }
      .nav-link {
        padding: 0;
        display: flex;
        align-items: center;
        align-content: center;
        line-height: 1;
        .icon-item {
          margin-right: .25rem;
        }
      }
    }
    .statuses-heading {
      font-size: .75rem;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}
