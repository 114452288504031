@import "../../base_styles/custom_variables";

.pagination-component {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .page-switcher {
    display: flex;
    gap: 1.5rem;
  }
  .page-count-selector {
    .form-group {
      margin-bottom: 0;
    }
  }
  .no-data {
    margin: auto;
  }
}
