@import "../../../base_styles/custom_variables_2_0";

.checkbox-list {
  .checkbox-item {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1;
    padding-top: $baseRem*5;
    padding-bottom: $baseRem*5;
    margin-bottom: $baseRem*2;
    &.checked {
      &:before {
        font-family: 'Material Icons';
        content: '\e834';
        position: absolute;
        font-size: 20px;
        color: $primary;
        left: 6px;
      }
    }
    &:hover {
      cursor: pointer;
      .item-box {
        border-color: $dark-8;
      }
    }
    &.active {
      background: $secondary-light;
    }

    .item-box {
      width: 1rem;
      height: 1rem;
      border: 1px solid $input-border-color;
      border-radius: 2px;
      margin-right: .5rem;
    }
    .item-label {
      flex: 1;
      word-break: break-all;
    }
  }
  user-select: none;
  input[type="text"]:hover {
    cursor: text;
  }
  .custom-control {
    display: flex;
    label {
      display: flex;
      align-items: center;
    }
  }
}
