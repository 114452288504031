@import "../../base_styles/custom_variables_2_0";

.page {
  padding-top: 1.5rem;
  .topbar,  .page-content-wrapper {
    width: calc(100% - 2rem);
    margin: 0 auto;
  }
  .topbar {
    margin-bottom: 2rem;
  }
  @media (min-width: 1200px){
    .topbar {
      width: calc(100% - 4rem);
      margin-bottom: 3rem;
    }
    .page-content-wrapper {
      width: calc(100% - 18.75rem);
    }
  }



}
