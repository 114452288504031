@import "../../../base_styles/custom_variables_2_0";

.custom-button {
  display: flex;
  position: relative;
  border-radius: $button-border-radius;
  span {
    user-select: none
  }
  &.btn-outline-primary {
    border-color: $dark-6;
    background: $white;
    color: $dark-10;
    &:hover {
      border-color: $dark-7;
      background: $white;
      color: $primary;
      box-shadow: none;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active {
      border-color: $dark-7;
      background: $dark-3;
    }
  }

  &.btn-black {
    border-color: $dark-10;
    background: $dark-10;
    color: $white;
    &:hover {
      box-shadow: 0 4px 10px rgba(2, 1, 24, 0.4);
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active {
      border-color: $dark-10;
      background: $dark-10;
      box-shadow: none;
    }
  }

  &.btn.icon-left {
    padding: .8125rem $baseRem*15 .8125rem .6875rem;
    .icon-wrapper {
      margin-right: $baseRem*8;
    }
    &.is-link {
      padding: 0
    }
    &.btn-sm {
      padding: $baseRem*5 $baseRem*11 $baseRem*5 $baseRem*7;
      .icon-wrapper {
        margin-right: $baseRem*6;
      }
    }
  }

  &.btn.icon-left.icon-only {
    padding: $baseRem*13;
    .icon-wrapper {
      margin: 0;
    }
    &.btn-sm {
      padding: $baseRem*5;
      .icon-wrapper {
        margin: 0;
      }
    }
  }

  &.btn.icon-right {
    padding: .8125rem .6875rem .8125rem  $baseRem*15;
    .icon-wrapper {
      margin-left: $baseRem*8;
    }
    &.btn-sm {
      padding: $baseRem*5 $baseRem*7 $baseRem*5 $baseRem*11;
      .icon-wrapper {
        margin-left: $baseRem*6;
      }
    }
    &.is-link {
      padding: 0;
    }
  }

  &.animated-icon {
    &:hover {
      box-shadow: none;
    }
    .icon-wrapper {
      animation: animated-icon 0.7s linear infinite;
    }
  }

  &.is-link {
    text-transform: none;
    font-weight: $font-weight-light;
    border-radius: 0;
    &:hover {
      color: $primary-dark;
    }
  }
  @keyframes animated-icon {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  span {
    white-space: nowrap;
  }

}
