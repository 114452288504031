@import "../../../base_styles/custom_variables_2_0";

.service-details-main {
  .info-item {
    display: flex;
    flex-direction: row;
    padding: .5rem;
    margin-bottom: 1rem;
    place-items: center;

    .info-icon {
      width: 4.75rem;
      height: 4.75rem;
      display: flex;
      background: $dark-3;
      border-radius: .25rem;
      color: $text-color-title;
      margin-right: 1.5rem;

      span {
        margin: auto;
      }
    }

    .info-content {
      font-weight: $font-weight-normal;

    }

    .info-title {
      font-size: $font-size-14;
      text-transform: uppercase;
      margin-bottom: .5rem;
      color: $text-color-secondary;
    }

    .info-data {
      font-size: $font-size-18;
      color: $text-color-primary;
    }

    .icon-text {
      font-size: $font-size-12;
      font-weight: $font-weight-bold;
    }
  }
}

.info-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  &.expandable {
    cursor: pointer;
    .info-data {
      max-height: 5.5rem;
      overflow: hidden;
      position: absolute;
      background: $white;
      padding: .25rem;
      border: 1px solid $dark-3;
    }
    &.show  {
      .info-data {
        max-height: none;
        z-index: 5;
      }
    }

    .info-title {
      .icon-item {
        color: $primary;
      }
    }
  }

  .info-icon {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    background: $dark-3;
    border-radius: $button-border-radius;
    border: 1px solid $dark-5;
    color: $dark-10;
    span {
      margin: auto;
    }
  }

  .info-content {
    padding: .5rem;
  }

  .info-title {
    font-size: $font-size-10;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    margin-bottom: .5rem;
    color: $dark-10;
    display: flex;
    align-items: center;
    column-gap: .25rem;
    a {
      font-weight: $font-weight-normal;
    }
  }

  .info-data {
    font-size: $font-size-14;
    .font-weight-bold {
      color: $dark-10;
    }
  }

  .icon-text {
    font-size: $font-size-12;
    font-weight: $font-weight-bold;
  }
}
