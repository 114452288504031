@import "../../../base_styles/custom_variables_2_0";

.form-inline {
  .custom-select-wrap {
    display: flex;
    align-items: center;
    .form-label {
      flex-shrink: 0;
      font-weight: $font-weight-normal;
    }
  }
  label {
    margin-right: .5rem;
    font-weight: 500;
  }
}

.dropdown-select-wrap {
  input[type='text'] {
    position: absolute;
    top: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width: calc(100% - 2rem);
  }

  .btn {
    padding-left: .75rem;
    padding-right: .75rem;
    border-radius: $input-border-radius;
  }
  .dropdown-menu {
    max-height: 21rem;
    overflow-y: auto;
    .dropdown-item {
      padding: .5rem 1rem;
      font-size: $font-size-16;
    }
  }
  & + .custom-progress {
    margin-top: .25rem;
  }
}

.dropdown-select-box {
  &.disabled {
    label {
      color:$placeholder-and-passive-text-color;
    }
    .dropdown-select-wrap {
      .icon-item {
        color:$placeholder-and-passive-text-color;
      }
    }
  }
  .sub-message {
    position: absolute;
    top: 105%;
    left: 1rem
  }
}

.dropdown-select-wrap {
  position: relative;
  cursor: pointer;

  .custom-select {
    padding-right: 2rem;
  }

  .icon-item {
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .5rem;
    color: $dark-10;
  }
}

