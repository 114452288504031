$material-icons-font-path: './assets/fonts/';
@import '~material-icons/iconfont/material-icons.scss';
@import "~react-toastify/dist/ReactToastify.css";
@import "base_styles/custom_mixins";
@import "base_styles/custom_variables";
@import "base_styles/custom_variables_2_0";
@import "~bootstrap/scss/bootstrap";


body {
  overflow-x: hidden;
  overflow-y: scroll;
  color: $dark-9;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.page {
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 150px);
  >.spinner {
    flex-grow: 1;
    justify-self: center;
    margin: auto;
  }
}

.grid-center {
  display: grid;
  place-items: center;
}

h1, .h1 {
  font-weight: 300;
  font-size: $h1-font-size;
  margin-bottom: 1rem;
  color: $title-text-color;
}

h2, .h2 {
  font-weight: $h2-font-weight;
  margin-bottom: .25rem;
  color: $title-text-color;
}

h3, .h3 {
  font-weight: $h3-font-weight;
  color: $title-text-color;
}

h4, .h4 {
  font-weight: $h4-font-weight;
  color: $title-text-color;
}

h5, .h5 {
  font-weight: $h5-font-weight;
}

h6, .h6 {
  font-weight: $h6-font-weight
}

//Buttons

.btn {
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-weight: 500;
  font-size: $font-size-14;
  line-height: 1rem;
  padding: .9375rem;
  position: relative;
  &[aria-label]:hover:after {
    content: attr(aria-label);
    position: absolute;
    top: 110%;
    right: 0;
    padding: $tooltip-padding-y $tooltip-padding-x;
    background: $tooltip-bg;
    color: $tooltip-color;
    max-width: $tooltip-max-width;
    border-radius: .25rem;
    overflow: hidden;
    z-index: 1;
    text-transform: none;
    font-weight: $font-weight-light;
    * {
      background: transparent !important;
      border:none !important;
      margin: 0 !important;
      text-transform: none;
      color: $tooltip-color !important;
    }
  }
  @include hover {
    color: $dark-7;
    text-decoration: none;
  }

  &.btn-sm {
    padding: .4375rem .75rem;
  }

  &.btn-primary {
    @include button-var($primary, $white, $primary-dark, $white);
  }

  &.btn-secondary {
    @include button-var($blue-10, $primary, $blue-20, $primary);
  }

  &.btn-success {
    @include button-var($success, $white, $success-dark, $white);
  }

  &.btn-info {
    @include button-var($info, $white, $purple-60, $white);
  }

  &.btn-warning {
    @include button-var($warning, $white, $warning-dark, $white);
  }

  &.btn-danger {
    @include button-var($danger, $white, $danger-dark, $white);
  }

  &.btn-light {
    @include button-var($light, $primary, $gray-100, $primary);
  }

  &.btn-dark {
    @include button-var($dark, $white, $gray-600, $white);
  }

  &.btn-outline-primary {
    @include button-outline-var ($primary, $white)
  }

  &.btn-outline-secondary {
    @include button-outline-var ($secondary, $primary)
  }

  &.btn-outline-success {
    @include button-outline-var ($success, $white)
  }

  &.btn-outline-info {
    @include button-outline-var ($info, $white);
  }

  &.btn-outline-warning {
    @include button-outline-var ($warning, $white)
  }

  &.btn-outline-danger {
    @include button-outline-var ($danger, $white)
  }

  &.btn-outline-light {
    @include button-outline-var ($light, $primary);
    color: $primary
  }

  &.btn-outline-dark {
    @include button-outline-var ($dark, $white)
  }
  &.refresh-button {
    padding: .5rem .75rem;
  }
  &.short-button {
    padding: .5rem 1rem;
  }


  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
h2 + .refresh-button {
  margin-left: 1rem;
  margin-top: -.5rem;
}


//Links

a, .btn-link {
  &:hover,
  &:active,
  &:focus,
  &.active {
    text-decoration: none;
    outline: none;
  }
}

.action-wrapper {
  .sub-menu {
    font-size: .875rem;
    line-height: 1;
  }

  .nav-link {
    display: flex;
    align-items: center;
    padding: 0;

    .icon-item {
      margin-right: .25rem;
    }
  }
}


//Inputs
label {
  .required {
    display: inline-block;
    color: $danger;
    margin-left: .25rem;
    transform: scale(1.25);
  }
}

.form-control {
  border-radius: $input-border-radius;
  &:hover {
    border-color: $gray-300;

  }

  &:focus {
    border-color: $primary;
    box-shadow: none;

    label {
      color: $primary;
    }
  }
  &:disabled,
  &.form-control[readonly],
  &:disabled:hover,
  &.form-control[readonly]:hover{
    border-color: $dark-6;
    cursor: default;
  }
}


.form-control {
  height: 3rem;
  line-height: 1.2;
  padding: .5rem .75rem;
  &.form-control-sm {
    height: 2rem;
    font-size: $baseRem*14;
  }
}



.col-form-label-sm,
.form-control-sm {
  padding: .25rem .5rem;
}

.custom-select {
  //background: url("assets/images/dropdown_arrow.svg") no-repeat right 0.75rem center/8px 10px;
  background: none;

}

.form-row {
  & > .shifted {
    &[class*="col"] {
      .form-label {
        //left: .8rem;
      }
    }
  }
}

.input-wrapper {
  position: relative;
}

.shifted {
  position: relative;

  &[class*="col"] {
    .form-label {
      // left: 1.6rem;
    }
  }

  .form-control {
    background: $white;
    //border-width: 2px;
    &:disabled,
    &[readonly] {
     &:focus {
       border-color: $input-border-color ;
     }
    }
  }

  .form-label {
    position: absolute;
    font-size: .625rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    top: -.45rem;
    left: .5rem;
    background: $white;
    padding: .2rem .4rem .15rem;
    font-weight: 400;
    z-index: 5;

    &.custom-control-label {
      position: relative;
      top: initial;
      left: initial;
      display: flex;
      align-items: center;

    }
  }
}

.inline-checkbox-group {
  .form-label {
    color: $common-text-color;
  }
}

.custom-checkbox {
  .custom-control-label {
    cursor: pointer;

    &:before {
      border-radius: .1rem;
    }
  }

  .custom-control-input {
    &:focus:not(:checked) {
      & ~ .custom-control-label::before {
        box-shadow: none;
      }
    }

    &:focus {
      ~ .custom-control-label::before {
        box-shadow: none;
      }
    }
  }

}

//Dropdowns

.custom-dropdown {
  padding: 1rem 0;
  position: absolute;
  min-width: 100%;
  z-index: 10;
  max-height: 21rem;
  overflow-x: auto;
}

.dropdown-item {
  font-weight: $font-weight-light;
}

.dropdown-menu {
  min-width: 100%;
  border: none;
  box-shadow: 0 20px 30px rgba(10, 22, 43, 0.08);
}

//Cards

.panel {
  .heading {
    margin-bottom: 1rem;
  }
}

.card {
  border: 1px solid $dark-6;
  border-radius: $cards-border-radius;
  overflow: hidden;
  .heading {
    a {
      color: $text-color-title;
      font-weight: $font-weight-bold;
      &:hover {
        color: $primary;
      }
    }
  }

  .subtitle {
    color: $subtitle-text-color;
  }

  .card-header {
    background: $white;
    border-bottom-color: $dark-light;
    padding: .75rem 1rem;
  }

  .card-body {
    padding: 1rem;
  }
  &.card-item {
    margin-bottom: 2rem;
  }
  .card-grid & {
    &.card-item {
      margin-bottom: 0;
    }
  }
}


//Navs

.nav-tabs {
  margin-bottom: 2rem;
  border-bottom: transparent;
  padding-bottom: .125rem;
  flex-wrap: nowrap;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
  overflow-y:visible;
  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 1px;
    background: $dark-5;
    align-self: flex-end;
    margin-bottom: -1px;
  }
  &::-webkit-scrollbar {
    height: 0 !important;
  }

  .nav-item {
    .nav-link {}
    &.show {
      .nav-link {
        border-bottom-color: $nav-tabs-link-active-border-color;
      }
    }
  }

  .nav-link {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.75rem;
    padding: .6rem 1.25rem;
    border-radius: 4px 4px 0 0;
    border-bottom-color: $dark-5;
    color: $dark-9;
    white-space: nowrap;
    &.disabled {
      border-bottom-color: $dark-5;
    }
    &:focus {
      border-color: transparent;
      outline: none;
    }
    &.active {
      border-color: $nav-tabs-link-active-border-color;
    }

  }
}

//Tables


table {
  &.table {
    color: inherit;
    line-height: 1.3;
    width: 100%;
    thead {
      th {
        border: none;
        font-size: .75rem;
        font-weight: $font-weight-normal;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    tr:first-child {
      td {
        border-top-color: transparent;
      }
    }
    td {
      font-size: 0.875rem;
      font-weight: 300;
      vertical-align: middle;
      border-top-color: $dark-5;
      white-space: nowrap;
      padding: 0 1rem;
      height: 56px;
      overflow: hidden;
      text-overflow: ellipsis;

      .response-title {
        display: none;
      }
      &.actions {
        overflow: visible;
        //max-width: 4rem;
      }
      .buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        .btn + .btn {
          margin-left: .5rem;
        }
      }

      .custom-checkbox {
        margin-bottom: 0;
      }
    }
    .custom-dropdown {
      //display: inline-block;
      margin-left: auto;
    }
    .form-group {
      margin: 0;
    }

  }

  &.table-flex {
    thead {
      left: -999999999px;
      position: absolute;
      visibility: hidden;
    }

    tbody {
      // border: 1px solid $gray-100;

      tr {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border: 1px solid $gray-200;
        border-bottom-width: .5rem;
        &:last-child {
          border-bottom: none;
        }

        td {
          font-size: .8125rem;
          padding: 2rem .5rem .5rem;
          position: relative;
          width: 100%;
          border: solid $gray-100;
          border-width: 1px 0;
          &:last-child{
            text-align: left;
          }

          .response-title {
            color: $body-color;
            font-size: .75rem;
            font-weight: 500;
            text-transform: uppercase;
            display: block;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            padding: 0.25rem;
            border-top: none;
            border-bottom: 1px solid $nav-tabs-border-color;
          }
        }
      }
    }
  }


}
// Badges

.badge {
  text-transform: uppercase;
  padding: $baseRem*5 $baseRem*8;
  font-weight: $font-weight-extra-bold;
  border-radius: $button-border-radius;


  &.badge-primary {
    background: $primary-light;
    color: $primary-dark;
  }

  &.badge-secondary {
    background: $secondary;
    color: $text-color-primary;
  }

  &.badge-warning {
    background: $warning-light;
    color: $warning-dark;
  }

  &.badge-danger{
    background: $danger-light;
    color: $danger-dark;
  }

  &.badge-info {
    background: $info-light;
    color: $info-dark;
  }

  &.badge-success {
    background: $success-light;
    color: $success-dark;
  }

  &.badge-dark {
    background: $dark-light;
    color: $dark;
  }

}


@media (min-width: 576px) {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
  table {
    &.table-flex {
      tbody {
        tr {
          td {
            width: 50%;
            .response-title {
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {

  table {
    &.table-flex {
      tbody {
        tr {
          td {
           border:none;
            .response-title {
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1365px) {
  table {
    &.table-flex {
      color: inherit;
      line-height: 1.3;

      thead {
        position: relative;
        left: 0;
        visibility: visible;

        th {
          vertical-align: middle;
          border-top: none;
          font-size: .75rem;
          font-weight: 500;
          text-transform: uppercase;
          white-space: nowrap;

          &:last-child {
            text-align: right;
          }
        }
      }

      tbody {
        border: none;

        tr {
          display: table-row;
          border-bottom-width: 1px;
          border-left: none;
          border-right: none;

          td {
            display: table-cell;
            font-size: 0.875rem;
            font-weight: 300;
            border-top-color: $gray-100;
            vertical-align: middle;
            white-space: nowrap;
            padding: 1.5rem 0.75rem;
            width: auto;

            &:last-child {
              text-align: right;
            }

            .response-title {
              display: none;
            }
          }
        }
      }
    }
  }
}


// Alert
.alert-danger {
  background: $danger-light;
  color: $danger;
  border: 1px solid $danger;
}

//Effects

.semi-transparent {
  opacity: .1;
  transition: opacity 0.3s ease-in-out;
}
.no-transparent {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.big-shadow {
  box-shadow: 0 20px 30px rgba(10, 22, 43, 0.08);
  border-radius: 4px
}
