.button-icon {
  padding-left: 1rem;
  padding-right: 1rem;
  &.animated-icon {
    background: transparent;
    border-color: transparent;
    &:hover {
      background: transparent;
      box-shadow: none;
    }
    .icon-wrapper {
      animation: animated-icon 0.7s linear infinite;
    }
  }
}

@keyframes animated-icon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}