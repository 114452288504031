@import "../../../base_styles/custom_variables_2_0";

.health-check-details-view {
  display: flex;
  flex-direction: column;
  //flex: 1;
  .header-left {
    //padding-top: 1.5rem;
    .title {
      margin-bottom: 1rem;
    }
    .custom-select-advanced  {
      font-size: $h2-font-size;
      border: none;
      color: $text-color-title;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      option {
        font-size: 1rem;
      }
    }
    //.btn-badge {
    //  pointer-events: none;
    //  position: absolute;
    //  top: 0;
    //  border-top-right-radius: 0;
    //  border-top-left-radius: 0;
    //}
  }
  .header-right {
    //padding-top: 1.5rem;
    @media (max-width: 575px) {
      button:not(:last-child) {
        margin-bottom: .5rem;
      }
    }
    @media (min-width: 576px) {
      display: flex;
    }
  }
  .health-chek-details-info {
    display: flex;
    column-gap: 1.5rem;
    .info-item {
      margin-bottom: 0;
    }
    .info-item:not(:last-child) {

    }
    font-weight: $font-weight-bold;
    font-size: $font-size-14;
    .user-name-value {
      color: $text-color-title;
    }
  }

  .health-check-details-content {
    height: 66.6vh;
    overflow-y: auto;
  }
}
