@import "../../base_styles/custom_variables_2_0";
.barcodes-view {
  .barcodes-table-view.table {
    table-layout: fixed;

    thead {
      th {
        background: $dark-3;
        color: $text-color-title;
        padding: 1rem;

        &:first-child {
          border-radius: $input-border-radius 0 0 $input-border-radius;
          width: 3rem;
        }
        &:last-child {
          border-radius: 0 $input-border-radius $input-border-radius 0;
        }

        &.header-user,
        &.header-carrier {
          width: 9rem;
        }

        &.header-prefix {
          width: 6rem;
        }
        &.header-begin,
        &.header-end {
          width: 12rem;
        }

        &.header-remaining {
          width: 12rem;
        }

        &.actions {
          width: 6rem;
        }
      }
    }

    td {
      &:first-child {
      }
    }

    .ranges-details-list {
      display: none;
      background: $dark-3;

      &.is-open {
        visibility: visible;
        display: table-row;
      }

      & + .ranges-details-list {
        td {
         // border-top-color: transparent;
        }
      }
    }

    .ranges-common-data {
      td {
        &:hover {
          cursor: pointer;
          span {
            cursor: auto;
          }
        }
        .btn-link {
          color: $text-color-title;
        }
      }
      &.is-open {
        background: $dark-5;
      }
    }
  }

  .barcodes-card-view {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 1.5rem;
    .card-item {
      margin-bottom: 0;
    }
    .card-body {
      .common-data {
        margin-bottom: 1rem;
      }
      .details-data {
        display: none;
        &.is-open {
          display: block;
        }
        .ranges-details-list {
          padding: .5rem;
          margin-left: -.5rem;
          margin-right: -.5rem;
          margin-bottom: 1rem;
          background: $dark-3;
          border-radius: .25rem
        }
      }
    }
    .card-item-section {
      display: flex;
      margin-bottom: .75rem;
      line-height: .875rem;

      .card-item-label {
        font-size: $font-size-10;
        font-weight: $font-weight-normal;
        text-transform: uppercase;
        width: 6rem;
        margin-right: 1.5rem;
      }

      .card-item-data {
        font-size: $font-size-14;
        width: calc(100% - 7.5rem);
      }
    }
    .delete-barcode {
      &.custom-button.btn.icon-left.btn-sm {
        padding-left: 0;
      }
    }
    @media (min-width: 576px) {
      display: block;
      .card-item {
        margin-bottom: 1.5rem;
      }
      .card-body {
        .common-data {
          display: flex;
          .card-item-section {
            display: block;
            margin-right: 2rem;
            .card-item-label {
              margin-bottom: .5rem;
              margin-right: auto;
              width: auto;
            }
            .card-item-data {
              width: auto;
            }
          }
        }
        .details-data {
          display: none;
          &.is-open {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
            grid-gap: 1.5rem;
          }
          .ranges-details-list {
            .card-item-section {
              .card-item-label {
              }

              .card-item-data {

              }
            }
          }
        }
      }

    }
  }

  .delete-barcode {
    &:not([disabled]){
      color: $danger;
    }
  }
}
