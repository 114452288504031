@import "../../base_styles/custom_variables_2_0";
.user-info {
  color: $white;
  display: flex;
  margin: 0 1.25rem;
  align-items: center;
  .user-name {
    display: flex;
    align-items: center;
    font-weight: $font-weight-normal;
    margin-right: .5rem;
    max-width: 10rem;
    word-break: break-word;
    a {
      display: flex;
      align-items: center;
    }
    .icon-item {
      margin-right: .625rem;
    }
    .icon-letter {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: $font-size-22;
      color: $white;
      background: $warning;
      text-transform: uppercase;
      border-radius: 50%;
      width: 2.125rem;
      height: 2.125rem;
      margin-right: .5rem;
    }
  }
  .btn-link {
    color: inherit;
    padding-left: 0;
  }
}
