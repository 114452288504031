@import "../../../base_styles/custom_variables_2_0";

.sidebar-toggle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 1.5rem;
  width: 1.75rem;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;

  &.opened {
    transform: rotate(540deg);
    span {
      &:nth-child(3) {
        width: 50%;
      }

      &:nth-child(2) {
        width: 75%;
      }
    }
  }

  span {
    display: block;
    height: .25rem;
    width: 100%;
    background: $dark-10;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
  }
}
