.no-data {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  min-height: 20rem;
  .image-wrapper {
    margin-bottom: 2rem;
  }
}
