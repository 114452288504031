// Color system
$black: #000;
$gray-900: #0A162B; // Dark-100
$gray-800: #3b4456; // Dark-80
$gray-700: #495057;
$gray-600: #6c7380; // Dark-60
$gray-500: #adb5bd;
$gray-400: #9da2aa; // Dark-40
$gray-300: #ced0d5; // Dark-30
$gray-200: #e6e8ea; // Dark-20
$gray-100: #f3f3f4; // Dark-10
$gray-50: #f8f8f9; // Dark-50
$white: #fff;

$blue: #367BF5;
$blue-80: #309EFF;
$blue-60: #47ADFF;
$blue-40: #68BDFF;
$blue-30: #94D0FF;
$blue-20: #BFE2FF;
$blue-10: #E4F4FF;
$blue-5: #F1FAFE;

$purple: #7C4DFF;
$purple-60: #B094FF;
$purple-10: #F2EDFF;
$red: #ff5b5b;
$red-60: #FF9D9D;
$red-10: #FFEFEF;
$yellow: #FFB300;
$yellow-60: #FFD166;
$yellow-10: #FCF3D7;
$green: #3CC460;
$green-60: #91E6A7;
$green-10: #E6FFEC;


$primary: $purple;
$secondary: $blue-10;
$success: $green;
$success-light: $green-10;
$info: $purple;
$info-light: $purple-10;
$warning: $yellow;
$warning-light: $yellow-10;
$danger: $red;
$danger-light: $red-10;
$light: $gray-200;
$dark: $gray-900;
$dark-light: $gray-100;



// Body
//
// Settings for the `<body>` element

$body-bg:                   #F5F8FC;
$body-color:                $gray-900;
$body-color-secondary:      $gray-800;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-size-xs:                $font-size-base * .75 !default;
$font-size-xxs:               $font-size-base * .6125 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            500;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-light;
$line-height-base:            1.5 !default;

$h1-font-size:                2.625rem;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.625;
$h4-font-size:                $font-size-base * 1.375;
$h5-font-size:                $font-size-base * 1.125;
$h6-font-size:                $font-size-base;


$body1-color: $body-color-secondary;
$body2-color: $body-color-secondary;
$subtitle1-color: $body-color-secondary;
$subtitle2-color: $body-color-secondary;
$table-color: $body-color-secondary;
$caption-color: $body-color-secondary;
$overline-color: $body-color-secondary;
$placeholder-and-passive-text-color: $gray-400;

$title-text-color: $dark;
$common-text-color: $gray-800;
$secondary-text-color: $gray-600;
$disabled-text-color: $gray-300;
$subtitle-text-color: $secondary-text-color;
$placeholderPassive-text-color: $gray-400;


$body1-size: 1rem;
$body2-size: .875rem;
$subtitle1-size: $body1-size;
$subtitle2-size: $body2-size;
$table-size: $body2-size;
$caption-size: .75rem;
$overline-size: .65rem;


//$headings-margin-bottom:      $spacer / 2 !default;
$headings-font-family:        null !default;
$headings-font-weight:        500 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;

$display1-weight:             300 !default;
$display2-weight:             300 !default;
$display3-weight:             300 !default;
$display4-weight:             300 !default;
$display-line-height:         $headings-line-height !default;

$lead-font-size:              $font-size-base * 1.25 !default;
$lead-font-weight:            300 !default;

$small-font-size:             80% !default;

$text-muted:                  $gray-600 !default;

$blockquote-small-color:      $gray-600 !default;
$blockquote-small-font-size:  $small-font-size !default;
$blockquote-font-size:        $font-size-base * 1.25 !default;

$hr-border-color:             rgba($black, .1) !default;
//$hr-border-width:             $border-width !default;

$mark-padding:                .2em !default;

$dt-font-weight:              $font-weight-bold !default;

$kbd-box-shadow:              inset 0 -.1rem 0 rgba($black, .25) !default;
$nested-kbd-font-weight:      $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$mark-bg:                     #fcf8e3 !default;

//$hr-margin-y:                 $spacer !default;



// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-font-family:       null !default;
$input-btn-font-size:         .875rem;
$input-btn-line-height:       1;

$input-btn-focus-width:       .2rem !default;
//$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
//$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y:         1rem;

$input-btn-padding-y-sm:      .5rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      1rem;
$input-btn-line-height-sm:    1;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;
$input-btn-line-height-lg:    1;

$input-font-size: 1rem;

//$input-btn-border-width:      $border-width !default;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

//$btn-padding-y:               1.125rem;
$btn-padding-x:               2.375rem;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;

$btn-padding-y-sm:            0.825rem;
$btn-padding-x-sm:            1.5rem;
$btn-font-size-sm:            .6rem;

$btn-padding-y-lg:            1.5rem;
$btn-padding-x-lg:            2.375rem;
$btn-font-size-lg:            $input-btn-font-size-lg !default;

$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
//$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-disabled-color:     $gray-600 !default;

$btn-block-spacing-y:         .5rem !default;

// Allows for customizing button radius independently from global border radius
//$btn-border-radius:           $border-radius !default;
//$btn-border-radius-lg:        $border-radius-lg !default;
//$btn-border-radius-sm:        $border-radius-sm !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

//Navs

$nav-tabs-border-color:             $blue-30;
//$nav-tabs-border-width:             $border-width !default;
//$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $body-color;
$nav-tabs-link-active-bg:           $white;
$nav-tabs-link-active-border-color: $blue-30 $blue-30 transparent;



// Forms

$label-margin-bottom:                   0;

$input-padding-y:                       $input-btn-padding-y;

$input-border-color: $gray-200;
$input-box-shadow: none;

$form-group-margin-bottom:              2rem;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                10rem !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                0.875rem;
$dropdown-color:                    $body-color !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             rgba($black, .15) !default;
//$dropdown-border-radius:            $border-radius !default;
//$dropdown-border-width:             $border-width !default;
//$dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width}) !default;
$dropdown-divider-bg:               $gray-200 !default;
//$dropdown-divider-margin-y:         $nav-divider-margin-y !default;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) !default;

$dropdown-link-color:               $gray-900 !default;
$dropdown-link-hover-color:         darken($gray-900, 5%) !default;
$dropdown-link-hover-bg:            $blue-5;

//$dropdown-link-active-color:        $component-active-color !default;
//$dropdown-link-active-bg:           $component-active-bg !default;

$dropdown-link-disabled-color:      $gray-600 !default;

$dropdown-item-padding-y:           .25rem !default;
$dropdown-item-padding-x:           1.5rem !default;

$dropdown-header-color:             $gray-600 !default;



//Box shadow

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .125rem 1rem rgba(59, 68, 86, 0.05);
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

// Pagination

$pagination-padding-y:              1rem;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  $body-color;
//$pagination-bg:                     red !default;
//$pagination-border-width:           $border-width !default;
$pagination-border-color:           $gray-300 !default;

//$pagination-focus-box-shadow:       $input-btn-focus-box-shadow !default;
$pagination-focus-outline:          0 !default;

//$pagination-hover-color:            $link-hover-color !default;
$pagination-hover-bg:               $gray-200 !default;
$pagination-hover-border-color:     $gray-300 !default;

$pagination-active-color:           $white;
$pagination-active-bg:              $primary;
$pagination-active-border-color:    $primary;

$pagination-disabled-color:         $gray-600 !default;
$pagination-disabled-bg:            $white !default;
$pagination-disabled-border-color:  $gray-300 !default;



//GRID

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1600px
);

// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1520px
) !default;



// Modals

// Padding applied to the modal body

$modal-box-shadow:                   0 20px 30px rgba(10, 22, 43, 0.08);
$modal-border-color:                  $dark-light;


$modal-inner-padding:               1rem !default;

$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;

$modal-title-line-height:           $line-height-base !default;

$modal-content-color:               null !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        rgba($black, .2) !default;
//$modal-content-border-width:        $border-width !default;
//$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-box-shadow-xs:       0 20px 30px rgba(10, 22, 43, 0.08);
$modal-content-box-shadow-sm-up:    0 20px 30px rgba(10, 22, 43, 0.08);;

$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .5 !default;
//$modal-header-border-color:         $border-color !default;
//$modal-footer-border-color:         $modal-header-border-color !default;
//$modal-header-border-width:         $modal-content-border-width !default;
//$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            1rem !default;
$modal-header-padding-x:            1rem !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl:                          1400px !default;
$modal-lg:                          1140px !default;
$modal-md:                          500px !default;
$modal-sm:                          300px !default;

$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;

$table-color:                 $body-color !default;
$table-bg:                    null !default;
$table-accent-bg:             rgba($black, .05) !default;
$table-hover-color:           $table-color !default;
$table-hover-bg:              rgba($black, .075) !default;
$table-active-bg:             $table-hover-bg !default;

//$table-border-width:          $border-width !default;
//$table-border-color:          $border-color !default;

$table-head-bg:               $gray-200 !default;
$table-head-color:            $gray-700 !default;

$table-dark-color:            $white !default;
$table-dark-bg:               $gray-800 !default;
$table-dark-accent-bg:        rgba($white, .05) !default;
$table-dark-hover-color:      $table-dark-color !default;
$table-dark-hover-bg:         rgba($white, .075) !default;
$table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
$table-dark-color:            $white !default;

$table-striped-order:         odd !default;

$table-caption-color:         $text-muted !default;

$table-bg-level:              -9 !default;
$table-border-level:          -6 !default;



// Badges

$badge-font-weight:                 $font-weight-normal;
$badge-padding-y:                   .75em;
$badge-padding-x:                   1rem;
